<template>
  <div class="labels-container">
    <popover-cancel-save class="position-absolute absolute-center-y" style="right: 2px;" triggers="click clickOff"
                         title="Update Labels"
                         :value="labels"
                         @save="$emit('save', $event)"
                         :disable-editing="disableEditing">
      <template #edit="{ value, update }">
        <div class="mad-lib-container">
          <mad-lib-input :value="value || []" @input="update" disable-auto-focus placeholder="Add Labels"></mad-lib-input>
        </div>
      </template>

    </popover-cancel-save>
    <p-chip v-for="(label,idx) of labels" :key="idx" class="mr-2">
      {{label}}
    </p-chip>
  </div>
</template>

<script setup>
import PopoverCancelSave from '@/components/common/popoverCancelSave'
import MadLibInput from 'core-ui/components/inputs/madLibInput.vue'
import { computed } from 'vue'
const props = defineProps({
  item: Object,
  field: Object,
  lazyLoadEditor: Boolean
})

defineEmits(['save'])

const labels = computed(() => props.item?.[props.field?.dataKey || 'labels'] || [])
const disableEditing = computed(() => (props.field?.option && props.field.option === 'static') ?? false)

</script>

<style lang='scss' scoped>
  .labels-container {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

</style>
