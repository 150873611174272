<template>
  <div class="d-flex flex-column" :class="{'fixed-header': fixedHeader}">
    <latch-info2
      :account-plan-id="latchDialog.accountPlanId"
      :campaign-plan-id="latchDialog.campaignPlanId"
      :latches="latchDialog.latches"
      :show="latchDialog.show"
      @update:show="latchDialog.show = $event"
    />
    <div :class="{'d-flex-absolute-pane':useFlexClasses, 'flex-column':useFlexClasses, 'flex-grow-1': useFlexClasses}"
          :style="`width: ${widthStyle || '100%'}`">
      <div v-if="!isSubTable" :class="{'nav-bar-min-height': useNavBarMinHeight }" class="d-flex justify-content-between align-items-center px-0 flex-shrink-0 fluent-table-nav-bar">

        <slot name="left-nav"></slot>

        <div v-if="showFilter" class="pr-3" style="flex: 1 1 auto;">
          <slot name="filter">
            <content-filter v-if="showFilter"
                            v-model="searchFilter"
                            :placeholder="displayTableType ? `Type to search for ${displayTableType}s` : 'Type to search'"
                            prepend-icon="search"
                            clear-icon=""
                            clear-text="Clear"
                            size="md"
                            class="mt-2 mb-3"/>
          </slot>
        </div>

        <sub-table-advanced-filtering v-if="advancedFilterData"
                                      class="mt-3"
                                      :advanced-filter-data="advancedFilterData"
                                      @update:advanced-filter-data="advancedFilterData = $event"/>
        <div v-if="advancedFilterData && advancedFilterData.segments">
          <t-button id="advanceFilter"
                 variant="light"
                 class="dropdown-toggle position-relative mr-3"
                 @click="showAdvancedFiltering = !showAdvancedFiltering">
            <small>
              <fluency-icon type="filter"/>
            </small>
            Filter
            <p-badge v-if="numActiveFilters" class="ml-2">{{numActiveFilters}}</p-badge>
          </t-button>
        </div>

        <slot name="channelGroupFilter"></slot>

        <template v-if="tableType === 'budget' && $route.name === 'manage-manageType'">
          <mad-lib-select v-model="budgetShowActiveOnly"
                          :options="[{value: true, text: 'Hide Inactive'}, {value: false, text:'Show Inactive'}]"
                          variant="light"
                          button >
            <template #additional-content>
              <t-dropdown-divider></t-dropdown-divider>
              <t-dropdown-text text-class="font-weight-normal">*If a budget has a status of Paused or Removed and has not been updated for a week or longer, we mark the budget as Inactive. Marking a budget as inactive has no impact on the budget, it&apos;s only used to help declutter the list.</t-dropdown-text>
            </template>
          </mad-lib-select>
        </template>

        <template v-if="tableType === 'campaign' && $route.name === 'manage-manageType'">
          <mad-lib-select v-model="campaignShowActiveOnly"
                          :options="[{value: true, text: 'Hide Inactive'}, {value: false, text:'Show Inactive'}]"
                          variant="light"
                          button>
            <template #additional-content>
              <t-dropdown-divider></t-dropdown-divider>
              <t-dropdown-text text-class="font-weight-normal">*If a campaign has a status of Paused or Removed and has not been updated for a week or longer, we mark the campaign as Inactive. Marking a campaign as inactive has no impact on the campaign, it&apos;s only used to help declutter the list.</t-dropdown-text>
            </template>
          </mad-lib-select>
        </template>

        <slot name="additionalFilter"></slot>

        <div class="d-flex align-self-center align-items-center"
             :class="toolbarPosition === 'left' ? 'justify-content-start' : 'justify-content-end'"
             style="flex: 2 1 auto;"
             @click="trackTableActions">
          <div>
            <slot name="popDrawerButton"></slot>
          </div>
          <div v-if="showCreateNewAccount && tableActionsAllowed" class="ml-1">
            <t-button variant="ghost-primary" @click="createNewAccount()">
              <fluency-icon type="add" size="lg"/>
              <div class="small">Add</div>
            </t-button>
          </div>
          <div v-if="enableExcelImport && tableActionsAllowed">
            <import-excel />
          </div>
          <div v-if="enableReportBtn">
            <manage-report-definitions variant="ghost-secondary" no-caret>
              <template #button-content>
                <div class="d-flex align-items-center justify-content-center my-1">
                  <fluency-icon v-if="reportStillProcessing" type="loop" class="rotating p-0 m-0"></fluency-icon>
                  <fluency-icon v-else type="document"></fluency-icon>
                  <fluency-icon type="caretDown" class="ml-1 small"></fluency-icon>
                </div>
                <div class="small">Reports</div>
              </template>
            </manage-report-definitions>
          </div>
          <div v-if="showBroadcastButton && tableActionsAllowed">
            <nuxt-link class="btn btn-ghost-secondary" to="/customer/broadcast/">
              <fluency-icon type="publish"/>
              <div class="small">Broadcast</div>
            </nuxt-link>
          </div>
          <div v-if="parentHasReloadListener && !tableActionEllipses">
            <t-button v-p-tooltip.top="'Reload data'"
                      variant="ghost-secondary"
                      @click="$emit('reload')">
              <fluency-icon :class="{rotating: Object.values($store.getters.loading).some(l => l)}" size="lg" type="loop" />
            </t-button>
          </div>
          <div v-if="showDownloadButton && !tableActionEllipses">
            <t-button v-if="showDownloadButton" variant="ghost-secondary" :size="$attrs.small ? 'sm' : 'md'"
                      @click="downloadTable()"
                      v-p-tooltip.top="'Download CSV'"
                      class="download-csv">
              <fluency-icon type="download" :size="$attrs.small ? 'sm' : 'md'"/>
              <div v-if="showIconText" class="small">Download</div>
            </t-button>
          </div>
          <div v-if="columnSaveKey && !tableActionEllipses">
            <t-button v-p-tooltip.top="'Edit Columns'" variant="ghost-secondary"
                      class="text-left"
                     @click="$refs.columnEditPopoverRef.show($event)">
              <fluency-icon type="columns" size="lg"/>
              <div v-if="showIconText" class="small">Columns</div>
            </t-button>
            <p-popover ref="columnEditPopoverRef">
                <div class="d-flex justify-content-between border-bottom mb-3 pb-3">
                Select Columns
                <t-button-inline severity="secondary" class="text-left" @click="$refs.columnEditPopoverRef.hide()"><fluency-icon type="x" /></t-button-inline>
                </div>
              <column-edit :default-fields="fields"
                           :column-key="columnSaveKey"
                           @update="enhanceTableColumns"/>
            </p-popover>
          </div>

          <div v-if="tableActionEllipses">
            <t-dropdown variant="text" severity="secondary" no-caret @hide="inlineColumnsOpen = false" v-p-tooltip.top="`More Table Actions`" ref="tableActionDropdown">
              <template #button-content>
                <fluency-icon size="lg" type="ellipses" />
              </template>
              <li v-if="parentHasReloadListener" class="py-1 my-1"><t-button-inline fluid linkStyle severity="secondary" class="justify-content-start"  @click="$emit('reload')">Reload</t-button-inline></li>
              <li v-if="showDownloadButton" class="py-1 my-1"><t-button-inline fluid linkStyle severity="secondary" class="justify-content-start"  @click="downloadTable()">Download</t-button-inline></li>

              <slot name="additional-table-actions"></slot>

              <li v-if="columnSaveKey" class="py-1 my-1">
                <t-button-inline
                                   fluid
                                   linkStyle
                               class="justify-content-start"
                               severity="secondary"
                               @click.stop.prevent="inlineColumnsOpen = !inlineColumnsOpen; $refs.tableActionDropdown.alignDropdown()">Edit Columns <fluency-icon type="caretDown" :class="{'rotate-180': inlineColumnsOpen}" size="xs"/></t-button-inline>
              </li>
              <div v-if="inlineColumnsOpen" class="pt-2 px-2">
                <column-edit :default-fields="fields"
                             :column-key="columnSaveKey"
                             @update="enhanceTableColumns"/>
              </div>
            </t-dropdown>
          </div>

          <div class="ml-1">
            <slot name="addNewButton">
              <t-button v-if="parentHasAddListener"
                        variant="ghost-secondary"
                        @click="$emit('add')"
                        v-p-tooltip.top="`Add new ${displayTableType}`">
                <fluency-icon type="add"/>
              </t-button>
            </slot>
          </div>
          <slot name="right-nav"></slot>
        </div>
      </div>

      <template v-if="!isSubTable && tableToolbar && selectedRowObjs.length > 0">
        <div class="filter-and-bulk-bar position-absolute w-100 d-flex align-items-center top-0 left-0"
             :class="{ 'invisible' : selectedRowObjs.length === 0 || !items || items.length === 0 }">
          <table-bulk-edit-bar v-if="tableToolbar"
                               :buttons="tableToolbar"
                               :label="tableType"
                               :selected-row-key="selectedRowKey"
                               :brief-selection-text="briefSelectionText"
                               @toggleExpandCollapse="rowDetailsToggled($event, true)" />
          <h3 class="mb-0 ml-auto p-4 cursor-pointer"
              style="height: 46px;"
              @click="removeBar()">
            <fluency-icon type="close" />
          </h3>
        </div>
      </template>
      <template v-if="!isSubTable && columnFilters && Object.keys(columnFilters).length > 0">
        <div class="d-flex align-items-center">
          <p-chip v-for="(options, column) in columnFilters"
                  :key="column"
                  severity="info"
                  class="py-2 px-3 my-2 ml-3"
                  removable
                  @remove="$store.commit('table/removeColumnFilter', {table: computedColumnFilterKey, key: column})">
            {{$filters.convertFromCamelCase(options.label || column)}}:
            {{options.text}}
            <template v-if="['campaignChannel', 'budgetChannel'].includes(column)">
              {{options.checkboxValues.map(v => getAdvertisingChannelLabel(v)).join(', ')}}
            </template>
            <template v-else-if="column === 'channels'">
              {{options.checkboxValues.map(v => getPartnerLabel(v)).join(', ')}}
            </template>
            <template v-else-if="options.checkboxValues">
              {{options.checkboxValues.map(cbv => cleanText(cbv)).join(', ')}}
            </template>
            {{!isNaN(options.equals) ? `= ${options.formatter(options.equals)}` : ''}}
            {{!isNaN(options.lessThan) ? `< ${options.formatter(options.lessThan)}` : ''}}
            {{!isNaN(options.greaterThan) ? `> ${options.formatter(options.greaterThan)}` : ''}}
          </p-chip>
        </div>
      </template>

      <div v-if="advancedFilterData && advancedFilterData.segments">
        <advanced-filters v-if="advancedFilterData"
                          :expanded="showAdvancedFiltering"
                          :advanced-filter-data="advancedFilterData"
                          @update:advanced-filter-data="advancedFilterData = $event"
                          :options="advancedFilterOptions"
                          class="mt-3"
                          @update:expanded="showAdvancedFiltering = $event"
                          @activeFilterCounter="setNumActiveFilters" />
      </div>

      <div v-if="!items || !fields ">
        <fluency-loader>Loading {{planTypeLabel(tableType)}}...</fluency-loader>
      </div>
      <div v-else-if="!showHeadersWhenEmpty && ((!Object.values(items).length && advancedFiltersNotApplied) || enhancedColumnFieldDefinitions.length === 0)" class="m-4">
        <div
          v-if="$store.getters.loading[`${tableType}`] === true || ($store.getters.loading[`creative`] === true && tableType === 'ad')">
          <fluency-loader>Loading {{planTypeLabel(tableType)}}...</fluency-loader>
        </div>
        <div v-else>
          <slot name="emptyItemsMessage">
            <template v-if="tableType">
              No {{planTypeLabel(tableType)}} configured.
            </template>
            <template v-else>
              No items to display.
            </template>
            <div v-if="(parentHasAddListener || $slots.addNewButton) && $store.getters.activeItem && !!tableType && tableType !== 'criterion'" class="d-inline-block">
              Add one now.
              <slot name="addNewButton">
                <t-button variant="ghost-primary" @click="$emit('add')" v-p-tooltip.top="`Add new ${displayTableType}`">
                  <fluency-icon type="add" size="lg"/>
                </t-button>
              </slot>
            </div>
            <t-button variant="link" v-else-if="tableType === 'account'" @click="createNewAccount()">
              Add one now.
            </t-button>
          </slot>
        </div>
      </div>

      <div v-else :class="tableClasses">

        <div v-if="isInlineLoading" class="d-flex justify-content-center align-items-center position-absolute w-100 h-100">
          <fluency-loader inline dots color />
        </div>
        <slot name="bulkEditing"></slot>
        <!--
                :filter="searchFilter"
                :filter-ignored-fields="cFilterIgnoredFields"
                :filter-included-fields="cFilterIncludedFields"
        -->
        <new-table
                  :key="updateKey"
                 :hover="hover"
                 show-empty
                 :foot-clone="!totalsProp && footClone"
                 :no-footer-sorting="true"
                 :primary-key="primaryKey"
                 ref="bootstrapTable"
                 :table-class="tableClass"
                 :thead-class="theadClass"
                  :tfoot-class="tfootClass"
                 thead-tr-class="text-right"
                 tfoot-tr-class="text-right font-numeric"
                 :tbody-tr-class="tbodyTrClasses"
                 :items="pagedItems"
                 :no-local-sorting="true"
                 :sort-compare="fluentSorter"
                 :fields="enhancedColumnFieldDefinitions"
                 @filtered="catchFilteredItems"
                 :sort-by="sortTableBy"
                 @update:sort-by="sortTableBy = $event, $emit('update:sort-by', $event)"
                 @update:sortBy="sortTableBy = $event, $emit('update:sortBy', $event)"
                 :sort-desc="sortTableDesc"
                 @update:sort-desc="sortTableDesc = $event, $emit('update:sort-desc', $event)"
                 :sort-null-last="$attrs['sort-null-last'] || false"
                 :selectable="isTableSelectable"
                 :select-mode="$attrs['select-mode'] || 'multi'"
                 :selected-variant="$attrs['selected-variant'] || 'primary'"
                 :no-select-on-click="noSelectOnClick"
                 :sort-icon-left="sortIconLeft"
                 @row-clicked="$emit('row-clicked', $event)"
                 @row-dblclicked="$emit('row-dblclicked', $event)"
                 @row-selected="$emit('row-selected', $event)"
                 @row-hovered="loadHiddenRowItems"
                 @sort-changed="lazyItems = doSort($event)"
                 :responsive="$attrs.responsive ? true : false"
                 :small="$attrs.small ? true : false"
                 :fixed="$attrs.fixed ? true : false"
                 :tbody-transition-props="$attrs['tbody-transition-props'] || null"
                 :filter="textFilter"
                  :sticky-header="$attrs.stickyHeader ? $attrs.stickyHeader : false">
<!--          -->
<!---->
          <template #empty>
            <slot name="empty">
              <div class="text-left my-3 mx-5">
                There are no records to show.
                <saved-segment-clearer v-if="emptyFilterPrompt" />
              </div>
            </slot>
          </template>

          <!-- --------------------------------------------------------------------------------------------------- -->
          <!-- -- TABLE ADDITIONAL HEADING GROUPS ---------------------------------------------------------------- -->
          <!-- --------------------------------------------------------------------------------------------------- -->
          <template v-slot:thead-top>
            <template v-for="(c, ci) in computedColSpans" :key="`thead-${c.key}-${ci}`">
              <th v-if="c.colspan === 0"
                    :class="['border-top-0 border-bottom-0', c.key, {'th-sticky': c.field.sticky}]"
                    role="columnheader"
                    scope="col"
                    :style="c.field.thAttr ? c.field.thAttr.style : ''"></th>
              <th v-if="c.colspan > 0"
                    :key="`multi-${ci}`"
                    :colspan="c.colspan"
                    role="columnheader"
                    scope="colgroup"
                    :style="`width: ${colSpans[c.label].width * c.colspan}px`"
                    :class="['customTableHeading', 'text-center', 'border-top-0 border-bottom-0', 'text-capitalize']">
                <template v-if="c.label === 'underspend'">
                  Underspend Mitigation
                  <span class="float-right text-muted font-weight-normal underspend-legend">
                    Active
                  </span>
                </template>
                <template v-else>
                  {{c.label}}
                </template>
              </th>
            </template>
          </template>

          <!-- --------------------------------------------------------------------------------------------------- -->
          <!-- -- COMPARABLE METRICS ----------------------------------------------------------------------------- -->
          <!-- --------------------------------------------------------------------------------------------------- -->
            <template v-for="comparableMetric of comparableMetrics" :key="`comparable${comparableMetric}`"
                      v-slot:[`cell(${comparableMetric})`]="row">
              <delta-cell :previous="(row.item.previousMetricsDTO) ? row.item.previousMetricsDTO[comparableMetric]:false"
                          :current="row.item[comparableMetric]"
                          :type="row.field.key"
                          :formatter="row.field.formatter" />
            </template>

          <!-- --------------------------------------------------------------------------------------------------- -->
          <!-- -- TABLE HEADINGS --------------------------------------------------------------------------------- -->
          <!-- --------------------------------------------------------------------------------------------------- -->
          <template v-slot:head()="row">
            <span v-if="row.field.tooltip" v-p-tooltip.top="row.field.tooltip">{{row.label}}</span>
            <template v-else>{{row.label}} <info-tooltip v-if="row.field.infoTooltip" :title="row.field.infoTooltip" /></template>
            <div v-if="row.field.resizable" :key="row.field.index">
              <column-resize-handle :key="row.field.index"
                                    :column="row.field.slot"
                                    :columnKey="row.field.key"
                                    :columnWidth="computedColumnWidths[row.field.slot]"
                                    :table-type="tableType"
                                    @column-resized="handleColumnResized($event)"/>
            </div>
          </template>

          <template #sort-control="{ field, label }">
            <slot name="sort-control" :field="field" :label="label" :sort="columnFilterSorter">
              <column-filter v-if="field.filter"
                             class="ml-1"
                             :field="field"
                             inline
                             :table-key="computedColumnFilterKey"
                             :left="sortIconLeft"
                             @sort="columnFilterSorter(field, $event)"></column-filter>
            </slot>
          </template>

          <template v-slot:head(optimizedDailyAmount)="row">
            <span v-if="row.field.tooltip && customerHasFacebook" v-p-tooltip.top="'The amount the Fluency Budget Manager calculates as the optimal daily spend to stay on pace with the budget line graph based on the prior MTD spend patterns and current MTD spend. This value may vary between Fluency and Facebook by up to $0.50 so as to not reset the algorithm at Facebook due to frequent, trivial daily pacing updates.'">{{row.label}}</span>
            <span v-else v-p-tooltip.top="'The amount the Fluency Budget Manager calculates as the optimal daily spend to stay on pace with the budget line graph based on the prior MTD spend patterns and current MTD spend.'">{{row.label}}</span>

            <div v-if="row.field.resizable" :key="row.field.index">
              <column-resize-handle :key="row.field.index"
                                    :column="row.field.slot"
                                    :columnKey="row.field.key"
                                    :columnWidth="computedColumnWidths[row.field.slot]"
                                    :table-type="tableType"
                                    @column-resized="handleColumnResized($event)"/>
            </div>
          </template>

          <template v-slot:head(channels)="row">
            {{row.label}}
            <div v-if="row.field.resizable" :key="row.field.index" class="d-inline">
              <column-resize-handle :column="row.field.slot"
                                      :columnKey="row.field.key"
                                      :columnWidth="computedColumnWidths[row.field.slot]"
                                    :table-type="tableType"
                                    @column-resized="handleColumnResized($event)"/>
            </div>
          </template>

          <template v-slot:head(name)="header">
            <span v-if="tableType" class="text-left">{{$filters.upperCaseCharOne($filters.labelizePlanType(`${tableType}s`))}}</span>
            <span v-else>{{header.label}}</span>
            <div v-if="header.field.resizable" :key="header.field.index">
              <column-resize-handle :column="header.field.slot"
                                    :columnKey="header.field.key"
                                    :columnWidth="computedColumnWidths[header.field.slot]"
                                    :table-type="tableType"
                                    @column-resized="handleColumnResized($event)"/>
            </div>
          </template>

          <template v-slot:head(delete)>
            <div class="d-inline-block mr-1"
                v-p-tooltip.top="isDeleteLocked
                    ? `Please unlock column to delete this ${displayTableType}s`
                    : `Click this lock to prevent accidently removing ${displayTableType}s`"
                 @click="isDeleteLocked = !isDeleteLocked">
              <fluency-icon :type="isDeleteLocked ? 'lock' : 'unlock'"
                            size="lg"
                            :class="{'locked-label text-muted': isDeleteLocked, 'text-danger': !isDeleteLocked}"/>
            </div>
          </template>

          <template v-slot:head(selectRow)>
            <div v-if="tableActionsAllowed" @click.stop>
              <b-form-checkbox class="no-animation"
                               :checked="selectedRowIds.length === numSelectableItems && selectedRowIds.length > 0"
                               :disabled="isSingleSelectMode"
                               :indeterminate="selectAllIndeterminate"
                               @change="toggleAllSelected($event)" />
            </div>
          </template>

          <template v-slot:head(pacing)="data">
            <div :class="(data.field.option === 'bar')?'text-center':'text-right'" v-p-tooltip.top="'Projected end of month pacing, includes current day updates, updated hourly'">{{data.field.label}}</div>
              <column-resize-handle v-if="data.field.resizable"
                                    :key="data.field.index"
                                    :column="data.field.slot"
                                    :columnKey="data.field.key"
                                    :columnWidth="computedColumnWidths[data.field.slot]"
                                    :table-type="tableType"
                                    @column-resized="handleColumnResized($event)"/>
          </template>

          <template v-slot:head(budgetShared)="row">
            <div class="d-flex">
              <div v-p-tooltip.top="'Shared/Explicit Budget'" class="text-center">
                <fluency-icon type="sharedBudget"></fluency-icon>
              </div>
            </div>
          </template>

          <!-- --------------------------------------------------------------------------------------------------- -->
          <!-- -- TABLE CELLS ------------------------------------------------------------------------------------ -->
          <!-- --------------------------------------------------------------------------------------------------- -->
          <template v-slot:cell(startDate)="row">
            <div class="d-flex justify-content-end align-items-center inline-edit">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                   :value="row.item"
                                   :overflow-visible="true"
                                   title="Update Start Date"
                                   triggers="click"
                                   @save="$emit('saved', $event)">
                <template #edit="{update}">
                  <campaign-start-and-end-dates :value="{...row.item}"
                                                :show-end-date="false"
                                                @input="update($event)"/>
                </template>
              </popover-cancel-save>

              <span class="pl-2">
                <template v-if="row.item.startDate">
                  {{momentFilter(row.item.startDate)}}
                </template>
                <template v-else-if="row.item.budgetPlan">
                  {{momentFilter(row.item.budgetPlan.startDate)}}
                </template>
              </span>
            </div>
          </template>

          <template v-slot:cell(endDate)="row">
            <div class="d-flex justify-content-end align-items-center inline-edit">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                   :value="row.item"
                                   :overflow-visible="true"
                                   title="Update End Date"
                                   triggers="click"
                                   @save="$emit('saved', $event)">
                <template #edit="{update}">
                  <campaign-start-and-end-dates :value="row.item"
                                                :show-start-date="false"
                                                @input="update($event)"/>
                </template>
              </popover-cancel-save>

              <span class="pl-2">
                <template v-if="row.item.endDate">
                  {{momentFilter(row.item.endDate)}}
                </template>
                <template v-else-if="row.item.budgetPlan">
                  {{momentFilter(row.item.budgetPlan.endDate)}}
                </template>
              </span>
            </div>
          </template>

          <template v-slot:cell(servingStatus)="row">
            <template v-if="row.item">
              <div v-if="row.item.servingStatus && row.item.servingStatus.servingStatus"
                  v-p-tooltip.top="{ value: $filters.convertFromCapsUnderscore(row.item.servingStatus.servingStatus), showDelay: 1000 }"
                  class="d-flex-center-end position-relative py-2">
                <p-tag :severity="convertButtonVariant(row.item.servingStatus.variant)"
                       class="py-2 text-nowrap">
                  {{row.item.servingStatus.shortLabel}}
                </p-tag>
              </div>
            </template>
          </template>

          <template v-slot:cell(selectRow)="row">
            <div v-if="tableActionsAllowed" @click.stop>
              <b-checkbox :checked="selectedRowIds.includes(row.item[primaryKey])"
                          type="checkbox"
                          class="no-animation"
                          :class="{ 'disabled': disableableRows && row.item._disableRowSelection }"
                          :disabled="disableableRows && row.item._disableRowSelection"
                          data-qa="fluentTable-td-selectRow-checkbox"
                          @change="doSelectLogic(row, $event)" />
            </div>
          </template>

          <template v-slot:cell(conservativeBudgetIncrease)="row">
            <template v-if="row.item.conservativeBudgetIncrease > 0">
              <view-proposal :account-plan-id="row.item.accountPlanId"
                            :button-text="$filters.currency(row.item.conservativeBudgetIncrease)"
                            :account-name="row.item.name"
                            button-style="outline-primary"/>
            </template>
          </template>

          <template v-slot:cell(delete)="row">
            <span>
              <t-button v-if="row.item.status && row.item.status !== 'REMOVED'"
                        @click.stop="deleteItem(row.item)"
                        variant="link"
                        class="p-0"
                        :class="isDeleteLocked ? 'no-hover cursor-default' : ''"
                        v-p-tooltip.top="{ value: isDeleteLocked ? `Click the lock icon to unlock column and delete this ${displayTableType}` : `Remove this ${displayTableType}. THIS ACTION CANNOT BE UNDONE`, showDelay: 1000 }">
                <fluency-icon type="delete" class="text-secondary"></fluency-icon>
              </t-button>
            </span>
          </template>

          <!-- special row-details override -->
          <template v-slot:row-details="row">
            <slot v-bind="row" name="row-details">
              <fluent-table v-if="row.item.children"
                            :key="subFieldKeyCounter"
                            :class="subTableClasses"
                            :plan-type="tableType"
                            :column-save-key="columnSaveKey"
                            :selected-row-override="`sub-${tableType}`"
                            :items="row.item.children.filter(e => e.status !== 'REMOVED' || showRemoved)"
                            :fields="subFields"
                            thead-class="d-none"
                            tfoot-class="d-none"
                            :fixed-header="false"
                            :is-sub-table="true"
                            :column-filter-key="computedColumnFilterKey"
                            :sortBy="sortTableBy"
                            :sortDesc="sortTableDesc"
                            :use-flex-classes="false"
                            @saved="$emit('saved-po', $event)" />
              <span v-else></span>
            </slot>
          </template>

          <template v-slot:cell(campaignGroupName)="row">
            <div class="d-flex align-items-center justify-content-end inline-edit">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                  :value="row.item.campaignGroupName"
                                  @save="$emit('saved', {...row.item, campaignGroupName: $event, campaignGroupId: $event === null ? '9223372036854775807' : null})"
                                  ok-text="Apply"
                                   title="Update Campaign Group"
                                  triggers="click clickOff"
                                  :disable-editing="(row.field.option && row.field.option === 'static')">
                <template #edit="{value, update, validate, loading}">
                  <edit-campaign-group :value="value" @fetchStatus="validate($event !== 'error'); loading($event === 'loading')" @input="update"
                                      :campaign-plan="row.item.campaignPlanId ? row.item : null"
                                      :budget-plan="!row.item.campaignPlanId && row.item.budgetPlanId ? row.item : null"
                                      show-inline-warning
                                      show-no-options/>
                </template>
              </popover-cancel-save><fluency-icon v-else class="invisible mr-2" type="edit"/>
              <span>{{row.item.campaignGroupName}}</span>
            </div>
          </template>

          <template v-slot:cell(name)="row">
            <div class="d-flex align-items-center inline-edit" :id="`plan-name-${row.item[primaryKey]}`" :class="{'inline-edit-sub-table': isSubTable}">
              <t-button variant="link" @click="rowDetailsToggled(row)"
                class="small text-success d-flex p-0 mr-2" :class="{invisible: typeof row.item._showDetails === 'undefined' }" >
                <fluency-icon :type="(row.item._showDetails)?'chevronDown':'chevronRight'" size="md"/>
              </t-button>
              <template v-if="row.field.option === 'editable'">
                <div v-p-tooltip.top="{ value: row.item.name, showDelay: 1000 }"
                    class="pl-2">
                  <t-button variant="link" class="p-0" @click.stop="$emit('name-clicked', row.item)">
                    <template v-if="row.item.name && row.item.name.length > 0">{{row.item.name}}</template>
                    <template v-else-if="row.item.namePlaceholder"><em>{{row.item.namePlaceholder}}</em></template>
                    <template v-else>{{row.item.name}}</template>
                  </t-button>
                </div>
                <div v-if="row.item.hasOwnProperty('productCatalogPreview') && row.item.productCatalogPreview.source === 'PINTEREST' && hoveredRowMap[row.item[getTableRowKey()]]" class="table-action-container absolute-center-y" style="right:3px;">
                  <t-button-inline v-if="row.item.hasOwnProperty('productCatalogPreview') && row.item.productCatalogPreview.source === 'PINTEREST'"
                                   severity="secondary" size="small" class="mx-1"
                                   data-qa="fluentTable-td-name-actionContainer-button-previewPinterest"
                                   v-p-tooltip.top="'Preview Pinterest Ads'"
                                   @click.stop.prevent="showProductCatalogPreview(row.item, $event)">
                    <fluency-icon type="pin" />
                  </t-button-inline>
                </div>
              </template>
              <template v-else-if="row.field.option && row.field.option.action">
                <t-button v-p-tooltip.top="{ value: row.item.name, showDelay: 1000 }"
                          class="p-0 action-item"
                          data-qa="fluentTable-td-name-buttonInline"
                          variant="link"
                          :disabled="!tableActionsAllowed"
                          @click.stop="launchAction(row.field.option.action, row.item)">
                  {{row.item.name}}
                  <small class="text-muted">({{row.item.campaignCount}})</small>
                </t-button>
              </template>
              <template v-else>
                <span v-if="row.field.option === 'html'"
                      v-p-tooltip.top="{ value: row.item.name, showDelay: 1000, escape: false }"
                      v-html="row.item.name"/>
                <span v-else v-p-tooltip.top="{ value: row.item.name, showDelay: 1000 }">
                  <template v-if="showEditPartnerName === `${row.item.name}${row.index}`">
                    <edit-partner-name v-model="row.item" @save="row.item.name = $event;showEditPartnerName = -1;" @cancel="showEditPartnerName = -1;" :plan-type="planType"/>
                  </template>
                  <template v-else>
                    <div>
                      {{ row.item.name }}
                      <div v-if="showSubItemEditing" class="table-action-container absolute-center-y" style="right:3px;">
                        <t-button-inline v-p-tooltip.top="{ value: 'Edit Name', showDelay: 1000 }"
                                         severity="secondary" size="small"
                                         @click="showEditPartnerName = row.item.name+row.index"><fluency-icon type="edit"/></t-button-inline>
                      </div>
                    </div>
                  </template>
                  <span v-if="isAdvancedMode" class="text-muted">{{ row.item[`${tableType}Id`] }}</span></span>
              </template>

              <div v-if="$route.name === 'settings-customer' && row.item.commonBillingEntityId" class="ml-3" v-p-tooltip.top="'This account is linked to other accounts for billing.'">
                <fluency-icon type="link" class="text-muted" />
              </div>

              <div v-if="!isSubTable && hoveredRowMap[row.item[getTableRowKey()]] && ['account', 'campaign', 'adGroup'].includes(tableType)" class="table-action-container absolute-center-y pt-1 px-1" style="right:3px;">
                <t-button-inline severity="secondary" size="large" class="mx-2"
                                 data-qa="fluentTable-td-name-actionContainer-button-openSettings"
                                 v-p-tooltip.top="$filters.convertFromCamelCase(tableType) + ' Settings & Tools'"
                                 @click.stop.prevent="$emit('open-settings', { item: row.item })">
                  <fluency-icon type="toolbox" size="lg"/>
                </t-button-inline>
                <t-button-inline v-if="['account', 'campaign'].includes(tableType)"
                                 severity="secondary" size="large" class="mx-2"
                                 data-qa="fluentTable-td-name-actionContainer-button-openTargeting"
                                 v-p-tooltip.top="$filters.convertFromCamelCase(tableType) + ' Targeting'"
                                 @click.stop.prevent="$emit('open-settings', { item: row.item, type: 'Target' })">
                  <fluency-icon type="target" size="lg"/>
                </t-button-inline>
                <t-button-inline v-if="tableType === 'account'"
                                 severity="secondary" size="large" class="mx-2"
                                 data-qa="fluentTable-td-name-actionContainer-button-openBlueprintData"
                                 v-p-tooltip.top="'Blueprint Data'"
                                 @click.stop.prevent="$emit('open-settings', { item: row.item, type: 'Blueprint' })">
                  <fluency-icon type="blueprints" size="lg"/>
                </t-button-inline>
                <link-generator v-if="colabUser && tableType === 'account' && !row.item.readOnlyMode" :value="row.item"></link-generator>
              </div>

            </div>
            <small v-if="row.item.shownBasedOnImpressions"
                  class="text-muted"
                  v-p-tooltip.top="{ value: 'This item is shown regardless of its status because it contributed to performance metrics during the selected time period.', showDelay: 1000 }">
              *Shown based on metrics</small>
          </template>

          <template v-slot:cell(budgetWeight)="row">
            <!--            would  be primarily for ad groups to start-->
            <div class="d-flex justify-content-end align-items-center inline-edit">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                   title="Update Budget Weight"
                                  :value="row.item.budgetWeight"
                                  @save="$emit('saved', {  ...row.item, budgetWeight: $event })">
                <template #edit="{update, value}">
                  <label class="mb-0">Budget Weight</label>
                  <div class="mad-lib-container">
                    <basic-mad-lib-input :value="value === DOUBLE_NULL ? null : value" format="BASIC_PERCENTAGE" @input="update($event)"></basic-mad-lib-input>
                    <t-button-inline v-if="!!row.item.budgetWeight" severity="secondary" class="ml-auto" @click.stop="update(DOUBLE_NULL)">
                      <fluency-icon type="delete"></fluency-icon>
                    </t-button-inline>
                  </div>
                </template>
              </popover-cancel-save>

              <span class="pl-2">{{row.value === DOUBLE_NULL ? '' : row.value}}</span>
            </div>
          </template>

          <template v-slot:cell(bid)="row">
            <div v-if="!hasCapability(row.item, 'AdGroupBid')"> - </div>
            <div v-else-if="row.item.bid && row.item.bidType !== 'TARGET_SPEND'" class="d-flex justify-content-end align-items-center inline-edit">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                  :value="row.item.bid"
                                  @save="$emit('saved', { ...row.item, bid: $event })"
                                  :disable-editing="row.field.option && row.field.option === 'static'">
                <template #edit="{value, update}">
                  <b-form-group label="Bid">
                    <currency-input :value="value"
                                    @input="update"
                                    precision="2"/>
                  </b-form-group>
                </template>
              </popover-cancel-save>
              <span class="pl-2">{{$filters.currency(row.item.bid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}</span>
            </div>
            <div v-else>(auto)</div>
          </template>

          <template v-slot:cell(bidModifier)="row">
            <div class="d-flex justify-content-end align-items-center inline-edit">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                  :value="row.item.bidModifier"
                                  @save="$emit('savedBidModifier', {  ...row.item, bidModifier: $event })">
                <template #edit="{update}">

                  <bid-adjustment-input :value="row.item.bidModifier"
                                        @input="update"/>
                </template>
              </popover-cancel-save>

              <span class="pl-2">
                <template v-if="row.value !== 0 && row.value !== 1">
                  {{row.value > 1 ? '+' : '-'}}
                  {{$filters.percentage(Math.abs(row.value - 1 ), 0) }}
                </template>
                <template v-else>
                  &mdash;<span class="invisible">%</span>
                </template>
              </span>
            </div>
          </template>

          <template v-slot:cell(targetingSetting)="row">
            {{$filters.capitalize(row.item.targetingSetting)}}
          </template>

          <template v-slot:cell(budgetName)="row">
            <div class="d-flex align-items-center inline-edit py-2">
              <t-button v-if="typeof row.item._showDetails !== 'undefined'"
                        variant="link"
                        @click="rowDetailsToggled(row)"
                        class="small text-success mr-1 p-0">
                <fluency-icon :type="(row.detailsShowing)?'chevronDown':'chevronRight'"/>
              </t-button>
              <span v-else style="width:1em;display:inline-block;"></span>

              <template v-if="row.item.status !== 'DISABLED'">
                <div v-p-tooltip.top="{ value: row.item.name, showDelay: 1000 }"
                     class="pl-2" >
                  <t-button-inline v-if="row.item.budgetPlanId && !row.item.budgetId" @click="$emit('name-clicked', row.item)">
                    {{row.item.name}}
                  </t-button-inline>
                  <span v-else>{{row.item.name}}</span>
                </div>
                <div v-if="hoveredRowMap[row.item[getTableRowKey()]]" class="table-action-container absolute-center-y pt-1 px-1" style="right: 3px;">
                  <t-button-inline severity="secondary" size="large" class="mx-2"
                                   data-qa="fluentTable-td-name-actionContainer-button-openSettings"
                                   v-p-tooltip.top="$filters.convertFromCamelCase(tableType) + ' Settings & Tools'"
                                   @click.stop.prevent="$emit('open-settings', { item: row.item })">
                    <fluency-icon type="toolbox" size="lg"/>
                  </t-button-inline>
                </div>
              </template>
              <del v-else class="pl-2">
                <t-button variant="link">{{row.item.name}}</t-button>
                <fluency-icon type="edit" class="invisible ml-2"/>
              </del>
            </div>
          </template>

          <template v-slot:cell(budgetChannel)="row">
            <budget-channel-selector :value="row.item"
                                    @input="row.item = $event; $emit('saved', $event)"/>
          </template>

          <template v-slot:cell(budgetSpendSoFar)="row">
            <span>
              <img v-if="row.item.budgetOptimizerStatusDTO && (row.item.budgetOptimizerStatusDTO.spendSoFar || []).length > 0"
                  :src="renderSparkLine(row.item.budgetOptimizerStatusDTO.spendSoFar, row.item.pacing)"
                  height="22"
                  width="140"/>
            </span>
          </template>

          <template v-slot:cell(channels)="row">
            <partner-icons :partners="returnPartnerForType(row.item)"
                          :primary-type="tableType"
                          :account-plan-id="row.item.accountPlanId"
                          :filter-partner-ids="(row.item.partnerId)?[row.item.partnerId]:[]"
                          :pending-work="row.item.pendingWork"
                          :lazy-load-tooltips="true"
                          :load-tooltips="hoveredRowMap[row.item[getTableRowKey()]]"
                          stop-propagation
            />
          </template>

          <template v-slot:cell(headline1)="row">
            <div class="d-flex justify-content-between align-items-center inline-edit">
              {{row.item.headline1}}
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                  v-model="row.item.headline1"
                                  @save="$emit('saved', row.item)">
                <template v-slot:edit="{value, update}">
                  <b-form-group label="Headline 1">
                    <b-form-input :value="value"
                                  @input="update"/>
                  </b-form-group>
                </template>
              </popover-cancel-save>
            </div>

          </template>

          <template v-slot:cell(keywordText)="row">
            <t-button v-if="typeof row.item._showDetails !== 'undefined'"
                      variant="link"
                      @click="rowDetailsToggled(row)"
                      class="small text-success mr-1 p-0">
              <fluency-icon :type="(row.detailsShowing)?'chevronDown':'chevronRight'"/>
            </t-button>
            <span v-else class="d-inline-block keywordTextBump" style="width:1em;"/>

            <span v-if="row.field.option !== 'static'" class="text-dark">
              {{row.item.keywordText}}
            </span>
            <template v-else>
              <div v-if="row.item.analysisAction"
                  :class="`keyword-${row.item.analysisAction.toLowerCase().replace('_', '-')}`"
                  style="width:1em;height:1em;"></div>
              <span>
                {{row.item.keywordText}}
                <span v-if="isAdvancedMode" class="text-muted">{{row.item.keywordId}}</span>
              </span>
            </template>
            <t-button-inline v-if="row.item.impressions > 0 && (tableType === 'campaign' || tableType === 'adGroup' || tableType === 'keyword')"
                             class="ml-2 hover-icon action-button"
                             severity="secondary" size="small"
                             @click.stop="$emit('viewSimilarMetrics', row.item)">
              <fluency-icon type="contrast"/>
            </t-button-inline>
          </template>

          <template v-slot:cell(budgetType)="row">
            <span>{{ $filters.convertFromCapsUnderscore(row.item.syntheticBudgetType || row.item.budgetType || row.item.budgetPlan?.syntheticBudgetType || row.item.budgetPlan?.budgetType) }}</span>
          </template>

          <template v-slot:cell(budgetShared)="row">
            <div v-if="row.item.explicitlyShared" v-p-tooltip.top="{ value: 'This Budget is Shared', showDelay: 1000 }" >
              <fluency-icon type="sharedBudget" size="sm" class="text-success"></fluency-icon>
            </div>
          </template>

          <template v-slot:cell(budgetAmount)="row">
            <del v-if="row.item.status === 'REMOVED'">
              {{$filters.currency(row.item.budgetAmount, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
              <fluency-icon type="edit" class="invisible ml-2" v-if="row.field.option !== 'static'"></fluency-icon>
            </del>
            <span v-else-if="row.item.budgetType === 'LIFETIME'">-</span>
            <div v-else>
              <span class="d-flex justify-content-end align-items-center">
                <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                     title="Update Monthly Budget"
                                    class="mr-2"
                                    width="350px"
                                    :value="row.item"
                                    @save="$emit('saved', $event)"
                                    ok-text="Apply"
                                    triggers="click clickOff"
                                    :show-save-btn="showBudgetAmountSaveBtn(row.item)"
                                     @applyEnterKeypressBubble="applyEnterKeypressBubble()"
                                    :disable-editing="(row.field.option && row.field.option === 'static')">
                  <template #edit="{value, update, applyEnterKeypressBubble}">
                    <budget-amount-editor-v2 v-if="value && $store.getters.activeItem"
                                             :value="value"
                                             :show-lifetime-input="false"
                                             :show-start-and-end-dates="false"
                                             :show-prorated-amount="false"
                                             :account-plan="$store.getters.activeItem.planType === 'account' ? $store.getters.activeItem : ($store.getters.activeItem.accountPlan || null)"
                                             blur-helper
                                             @enterKeypress="applyEnterKeypressBubble"
                                             @input="update"/>
                    <!-- case for handling next month budget editing on bulk budget worksheet -->
                    <budget-amount-editor-v2 v-else-if="value"
                                             :value="value"
                                             :show-lifetime-input="false"
                                             :show-start-and-end-dates="false"
                                             :show-prorated-amount="false"
                                             :account-plan="row.item"
                                             blur-helper
                                             @enterKeypress="applyEnterKeypressBubble"
                                             @input="update"/>
                  </template>
                </popover-cancel-save>

                <span v-if="row.item.prorationFactor && row.item.prorationFactor !== 1"
                      class="text-primary"
                      v-p-tooltip.top="{ value: `This month is targeting ${row.item.proratedAmount} due to proration.`, showDelay: 1000, disabled: !row.item.budgetAmount}"
                      v-html="budgetAmount(row.item.budgetAmount)"
                ></span>
                <span v-else v-html="budgetAmount(row.item.budgetAmount)" class="inline-editable-value"></span>

<!--                <span v-if="row.item.budgetPlanAdjustment && row.item.budgetPlanAdjustment.amount !== 0"-->
<!--                      class="d-block font-weight-bold">-->
<!--                  <span :class="row.item.budgetPlanAdjustment.amount > 0 ? 'text-success' : 'text-info'">-->
<!--                    {{row.item.budgetPlanAdjustment.amount > 0 ? '+' : '-'}}-->
<!--                    <template v-html="budgetAmount(row.item.budgetPlanAdjustment.amount)"/>-->
<!--                  </span>-->
<!--                </span>-->
                <underspend-protection-alert type="flag"
                                            :budget-plan="row.item"
                                            class="ml-2"/>
              </span>
              <div v-if="row.item.budgetPlanAdjustment && row.item.budgetPlanAdjustment.amount !== 0"
                    class="d-block font-weight-bold">
                <span :class="row.item.budgetPlanAdjustment.amount > 0 ? 'text-success' : 'text-info'">
                  {{row.item.budgetPlanAdjustment.amount > 0 ? '+' : ''}}<span v-html="budgetAmount(row.item.budgetPlanAdjustment.amount)" />
                </span>
              </div>
            </div>
          </template>
          <template v-slot:cell(nextBudgetAmount)="row">
            <del v-if="row.item.status === 'REMOVED'">
              {{$filters.currency(row.item.nextBudgetAmount, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
              <fluency-icon  class="invisible ml-2"  type="edit"/>
            </del>
            <div v-else-if="row.item.budgetType !== 'LIFETIME'">
                <span class="d-flex justify-content-end align-items-center">
                    <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                        class="mr-2"
                                        :value="row.item"
                                         title="Set Next Month's Budget"
                                        @save="$emit('saved', $event)"
                                        ok-text="Apply"
                                        :disable-editing="(row.field.option && row.field.option === 'static')">
                      <template #edit="{value, update}">
                        <next-month-budget-editor v-if="value && $store.getters.activeItem"
                                                :value="value"
                                                :account-plan="$store.getters.activeItem.planType === 'account' ? $store.getters.activeItem : ($store.getters.activeItem.accountPlan || null)"
                                                @input="update"/>
                        <!-- case for handling next month budget editing on bulk budget worksheet -->
                        <next-month-budget-editor v-else-if="value"
                                                  :value="value"
                                                  :account-plan="row.item"
                                                  @input="update"/>
                      </template>
                    </popover-cancel-save>

                    <span v-html="nextBudgetAmount(row.item.nextBudgetAmount)"></span>
                  </span>
            </div>
            <span v-else>-</span>
          </template>
          <template v-slot:cell(optimizedDailyAmount)="row">
            <div>
              <span class="d-flex justify-content-end align-items-center">
                <template v-if="showTodaysTargetEditing(row.item)">
                  <popover-cancel-save :disable-editing="(row.field.option && row.field.option === 'static')"
                                      :value="row.item.optimizedDailyAmount"
                                      @save="$emit('saved', {...row.item, optimizedDailyAmount: $event})">

                    <template #edit="{value, update}">
                      <b-form-group description="Enter the preferred optimized daily amount" label="Optimized Daily Amount">
                        <b-input-group prepend="$">
                          <b-form-input :value="value"
                                        @input="update"/>
                        </b-input-group>
                      </b-form-group>
                    </template>
                  </popover-cancel-save>
                </template>
                <span>{{optimizedDailyAmount(row.item.optimizedDailyAmount)}}</span>
              </span>
            </div>
          </template>

          <template v-slot:cell(lifetimeBudget)="row">
            <del v-if="row.item.status === 'REMOVED'">
              {{ row.value }}
              <fluency-icon class="invisible ml-2" type="edit"/>
            </del>
            <div class="d-flex justify-content-end align-items-center" :class="{'inline-edit':row.field.option === 'editable'}">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                  class="pr-2"
                                  :value="row.item"
                                  @save="$emit('saved',$event)"
                                  ok-text="Apply"
                                  triggers="click clickOff"
                                  :disable-editing="row.field.option !== 'editable' || row.item.budgetType !== 'LIFETIME' || row.item.endDate && $moment(row.item.endDate).isBefore($moment())">
                <template #edit="{value, update, applyEnterKeypressBubble}">
                  <budget-amount-editor-v2 v-if="value"
                                           :value="value"
                                           :account-plan="$store.getters.activeItem.planType === 'account' ? $store.getters.activeItem : ($store.getters.activeItem.accountPlan || null)"
                                           :show-lifetime-input="false"
                                           :show-start-and-end-dates="false"
                                           :show-prorated-amount="false"
                                           blur-helper
                                           @enterKeypress="applyEnterKeypressBubble"
                                           @input="update" />

                </template>
              </popover-cancel-save>
              <span>{{  row.value }}</span>
            </div>
          </template>

          <template v-slot:cell(deliveryMethod)="row">
            <del v-if="row.item.status === 'REMOVED'">{{$filters.lowercase(row.item.deliveryMethod)}}</del>
            <span v-else>{{$filters.lowercase(row.item.deliveryMethod)}}</span>
          </template>

          <template v-slot:cell(actions)="row">
            <template v-if="row.field.option.actions && row.field.option.actions.length > 0">
              <t-button variant="link" :key="'action' + actionIndex"
                        v-for="(action, actionIndex) in row.field.option.actions"
                        class="text-nowrap float-left action-item"
                        @click="launchAction(action.emit, row.item)">
                <fluency-icon type="announce"></fluency-icon>
                {{action.label}}
              </t-button>
            </template>
          </template>

          <template v-slot:cell(budget)="row">
            <div class="d-flex justify-content-end align-items-center">
              <template v-if="row.field.option === 'editable' && row.item.budgetPlan && row.item.budgetPlan.budgetType !== 'LIFETIME'">
                <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]]"
                                    :value="row.item"
                                    triggers="click clickOff"
                                     :title="`Update ${tableType.charAt(0).toUpperCase()}${tableType.substring(1, tableType.length)} Budget`"
                                    width="300px"
                                    @save="$emit('saved', $event)">
                  <template #edit="{value, update}">
                    <campaign-budget-selector v-if="value"
                                              :value="value"
                                              :account-budgets="$store.getters.activeAccountBudgets"
                                              @input="update($event)"
                                              :form-group-opts="{'labelCols': false, 'label-cols': false}"
                                              isPopover/>
                    <budget-amount-editor-v2 v-if="value.budgetPlan"
                                             :value="value.budgetPlan"
                                             :show-lifetime-input="false"
                                             :show-start-and-end-dates="false"
                                             :show-prorated-amount="false"
                                             blur-helper
                                             @input="update({...value, budgetPlan: $event})" />
                  </template>
                </popover-cancel-save>
                <span class="ml-2">{{$filters.currency(row.item.budget, 2, { code: row.item.currencyCode || row.item._currencyCode })}}</span>
              </template>
              <div v-else
                  :class="{'text-danger': (row.item.approvedAccountBudget && Math.round(row.item.approvedAccountBudget) !== Math.round(row.item.budget) && row.field.option !== 'no-highlight')}">
                <span v-if="!row.item.budgetPlan || row.item.budgetPlan.budgetType !== 'LIFETIME'">{{$filters.currency(row.item.budget, 2, { code: row.item.currencyCode || row.item._currencyCode })}}</span>
                <span v-else>-</span>
              </div>
              <fluency-icon v-if="row.item.budgetPlan && row.item.budgetPlan.explicitlyShared"
                            type="link"
                            class="ml-2 mt-1"
                            v-p-tooltip.top="{ value: row.item.budgetPlan?.name, showDelay: 1000, disabled: !row.item.budgetPlan }" />
              <underspend-protection-alert type="flag"
                                          :budget-plan="row.item.budgetPlan"
                                          class="ml-2"/>
            </div>
          </template>

          <template #cell(accountAppliedBudget)="row">
            <div :class="{'text-danger': !hasActiveChanneGroupFilter && row.field.option !== 'no-highlight' && row.item.hasOwnProperty('accountBudgetAligned') && row.item.accountBudgetAligned === false }">
              {{$filters.currency(row.item.budget, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
            </div>
          </template>

          <template v-slot:cell(attachedFeeds)="row">
            <attached-feeds :value="row.item.attachedFeeds"/>
          </template>

          <template v-slot:cell(stat)="row">
            <div v-if="row.item.status">
              <template v-if="row.field.option === 'static'">
                {{$filters.convertFromCapsUnderscore(row.item.status)}}
              </template>
              <template v-else-if="row.field.option === 'staticDisplay'">
                <t-button :variant="statusStaticDisplayInfo[row.item.status].variant"
                          class="active"
                          size="small"
                          style="cursor: initial;">
                  <fluency-icon :type="statusStaticDisplayInfo[row.item.status].icon" size="sm"/>
                </t-button>
              </template>
              <template v-else>
                <t-button v-if="row.item.status === 'REMOVED'"
                          disabled
                          severity="secondary"
                          size="small"
                          v-p-tooltip.top="{ value: `${displayTableType} is removed and cannot be re-activated`, showDelay: 1000 }">
                  <fluency-icon type="stop" size="sm" />
                </t-button>
                <p-select-button v-else
                                 :modelValue="row.item.status"
                                 size="small"
                                 class="playpause"
                                 :options="['ENABLED', 'PAUSED']"
                                 @click.stop
                                 @update:modelValue="saveStatus(row.item, $event)">
                  <template #option="{option}">
                    <fluency-icon :type="option === 'ENABLED' ? 'mediaPlay' : 'mediaPause'"></fluency-icon>
                  </template>
                </p-select-button>
              </template>

            </div>
          </template>

          <template v-slot:cell(pacing)="row">
            <template v-if="isNaN(row.item.pacing) || !row.item.pacing || row.item.pacing ==='Infinity'">
              <div class="text-right">&nbsp;-&nbsp;</div>
            </template>
            <template v-else-if="row.field.option && row.field.option === 'bar'">
              <div class="text-center" style="margin-right:1.3em;">{{$filters.percentage(row.item.pacing, 0)}}</div>
              <div style="height:1.5em; margin-top:-1.5em;"
                  :style="{width: `${Math.min(row.item.pacing * 95, 100)}%`, 'background-color': pacingColor(row.item.pacing)}"></div>
            </template>
            <template v-else-if="row.item.pacing">
              {{$filters.percentage(row.item.pacing, 0)}}
            </template>
          </template>

          <template v-slot:cell(approvedBudgetPacing)="row">
            <template v-if="isNaN(row.item.approvedBudgetPacing) || !row.item.approvedBudgetPacing || row.item.approvedBudgetPacing ==='Infinity'">
              <div class="text-right">&nbsp;-&nbsp;</div>
            </template>
            <template v-else-if="row.item.pacing">
              {{$filters.percentage(row.item.approvedBudgetPacing, 0)}}
            </template>
          </template>

          <template v-slot:cell(camp)="row">
            <nuxt-link v-if="row.item.campaignPlans && row.item.campaignPlans.length === 1"
                      :to="`/manage/campaign/?id=${row.item.campaignPlans[0].campaignPlanId}`"
                      v-p-tooltip.top="{ value: calculateTitle(row.item.campaignPlans), showDelay: 1000 }" append
                      class="d-inline">
              {{row.item.campaignPlans.length}}
            </nuxt-link>
            <template v-else-if="row.item.campaignPlans">
              <span v-p-tooltip.top="{ value: calculateTitle(row.item.campaignPlans), showDelay: 1000 }">{{row.item.campaignPlans.length}}</span>
            </template>
            <span v-else>0</span>
            /
            <span v-p-tooltip.top="'Total Campaign Count'">{{row.item.attachedCampaignPlanIds ? row.item.attachedCampaignPlanIds.length : 0}}</span>
          </template>

          <template v-slot:cell(proratePartialMonth)="row">
            <div class="d-flex-center-end">
              <b-checkbox v-if="row.field.option !== 'static'"
                          :checked="row.item.proratePartialMonth"
                          :disabled="row.item.budgetManagerConfiguration && row.item.budgetManagerConfiguration.budgetBiasStrategy && row.item.budgetManagerConfiguration.budgetBiasStrategy.indexOf('SEGMENT') > -1"
                          switch
                          @change="row.item.proratePartialMonth = $event; $emit('saved', row.item)"/>
              <span v-else class="mr-4">{{(row.item.proratePartialMonth)?'On':'Off'}}</span>
            </div>
          </template>

          <template v-slot:cell(campaignName)="row"> <!-- there's also a supported campaignPlanName that doesn't use a template slot -->
            <template v-if="row.item.campaignName">
              <span v-p-tooltip.top="{ value: row.item.campaignName, showDelay: 1000 }">
                {{row.item.campaignName}}
              </span>
            </template>
          </template>

          <template v-slot:cell(adGroupName)="row"> <!-- there's also a supported adGroupPlanName that doesn't use a template slot -->
            <template v-if="row.item.adGroupName">
              <span v-p-tooltip.top="{ value: row.item.adGroupName, showDelay: 1000 }">{{row.item.adGroupName}}</span>
            </template>
          </template>

          <template v-slot:cell(accountName)="row">
            <nuxt-link v-if="$route.query.accountId"
                      :to="{path: '/manage/account/', query: {id: $route.query.accountId}}">
              {{row.item.accountName}}
            </nuxt-link>
            <template v-else>{{row.item.accountName}}</template>
          </template>

          <template v-slot:cell(accountHold)="row">
            <span>
              <span v-if="row.item.accountHold" class="small text-danger">HOLD</span>
            </span>
          </template>

          <template v-slot:cell(budgetManagerPaused)="row">
            <span v-if="row.item.status === 'ENABLED'">
              <span v-if="row.item.campaignLatchHold"
                                  id="popit"
                                  class="small text-warning cursor-pointer"
                                  @click.stop="showLatchDialog(true, row.item.campaignPlanId, row.item.accountPlanId, row.item.latches)">
                TASK</span>
              <span v-else-if="row.item.budgetManagerPaused"
                    class="small text-danger"
                    v-p-tooltip.top="{ value: 'Hold due to exceeding budget', showDelay: 1000 }">HOLD</span>
            </span>
          </template>

          <template v-slot:cell(budgetCurrentTotalSpend)="row">
            <span
              :class="{'text-danger': (row.item.budgetOptimizerStatusDTO)?row.item.budgetOptimizerStatusDTO.exhausted:false}">
              {{$filters.currency(row.item.cost, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
              <!-- use cost for child budget elements BE copies budgetOptimizerStatusDTO.currentTotalSpend is copied to cost in getDetails call -->
            </span>
          </template>

          <template v-slot:cell(destinationUrl)="row">
            <div v-if="activeItem?.advertisingChannelId !== 2" class="absolute-center-y" style="right:0;">
              <popover-cancel-save :value="row.item.destinationUrl"
                                   title="Change Destination URL"
                                  @save="$emit('saved', {...row.item, destinationUrl: $event})">
                <template #edit="{value, update}">
                  <div class="mad-lib-container">
                    <basic-mad-lib-input :value="value" @input="update($event)"></basic-mad-lib-input>
                  </div>
                </template>
              </popover-cancel-save>
            </div>
            <template v-if="activeItem && activeItem.accountPlan && activeItem.accountPlan.primaryDomain && row.item.destinationUrl">
              <template v-if="row.item.creativeType !== 'DYNAMIC_PRODUCT_AD' && row.item.destinationUrl.indexOf(activeItem.accountPlan.primaryDomain) === -1">
                <p-badge v-p-tooltip.top="{ value: 'Warning: the destination URL domain is different than primary domain.', showDelay: 1000 }" severity="warn" class="n-badge text-white"><fluency-icon type="warning"/></p-badge>
                {{row.item.destinationUrl}}
              </template>
              <template v-else>
                {{row.item.destinationUrl}}
              </template>
            </template>
            <template v-else>
              {{row.item.destinationUrl}}
            </template>
          </template>

          <template v-slot:cell(creativePlan)="row">
            <template v-if="row.field.option === 'partner'">
              {{getPartnerLabel(row.item.partnerId)}}
            </template>
            <template v-else>
              <t-button v-if="typeof row.item._showDetails !== 'undefined'"
                        variant="link"
                        class="small text-success float-left mt-2 p-0"
                        @click="rowDetailsToggled(row)">
                <fluency-icon :type="(row.detailsShowing)?'chevronDown':'chevronRight'"></fluency-icon>
              </t-button>
              <ad-preview
                v-if="row.item.hasOwnProperty('adSpec')"
                :allow-click-to-bubble="true"
                :channel-id="row.item.advertisingChannelId"
                :creative-plan="row.item"
                :delta="row.item.revert"
                :show-preview-label="false"
                :smaller="true"
                style="margin-left:1.5em;" />
              <div v-else class="d-none">NO AD PREVIEW TO DISPLAY</div>
              <div v-if="row.item.creativeId && isAdvancedMode" class="text-right text-muted">
                <span v-p-tooltip.top="{ value: 'Creative ID at channel', showDelay: 1000 }">
                  ( {{row.item.creativeId}} )
                </span>
              </div>

              <div class="table-action-container absolute-center-y pt-1 px-1" style="right:3px;">
                <t-button-inline severity="secondary" size="large" class="mx-2"
                                 data-qa="fluentTable-td-name-actionContainer-button-openSettings"
                                 v-p-tooltip.top="$filters.convertFromCamelCase(tableType) + ' Settings & Tools'"
                                 @click.stop.prevent="$emit('open-settings', { item: row.item })">
                  <fluency-icon type="toolbox" size="lg"/>
                </t-button-inline>
              </div>
            </template>
          </template>

          <template v-slot:cell(readOnlyMode)="row">
            <span v-if="!row.item.readOnlyMode"
                  v-p-tooltip.top="{ value: 'Fluency Managed', showDelay: 1000 }"
                  :class="['read-only', whiteLabelClassName, 'm-auto']" />
            <span v-else>&nbsp;</span>
          </template>

          <template v-slot:cell(autoRemoveBlockingKeywordOnUnderspend)="row">
            <div class="d-flex-center-end">
              <b-checkbox v-if="row.field.option !== 'static' && hasCapability(row.item, 'UnderspendMitigation')"
                          :checked="row.item.autoRemoveBlockingKeywordOnUnderspend"
                          switch
                          @change="row.item.autoRemoveBlockingKeywordOnUnderspend = $event; $emit('saved', row.item)" />
              <span v-else class="mr-4">{{(row.item.autoRemoveBlockingKeywordOnUnderspend)?'On':'Off'}}</span>
            </div>
          </template>

          <template v-slot:cell(allowReallocation)="row">
            <div class="d-flex-center-end">
              <b-checkbox v-if="row.field.option !== 'static'"
                          :checked="row.item.allowReallocation"
                          switch
                          @change="row.item.allowReallocation = $event; $emit('saved', row.item)" />
              <span v-else class="mr-4">{{(row.item.allowReallocation)?'On':'Off'}}</span>
            </div>
          </template>

          <template v-slot:cell(budgetBiasStrategy)="row">
            <span v-if="row.item.budgetManagerConfiguration">{{$filters.labelizePacingStrategy(row.item.budgetManagerConfiguration.budgetBiasStrategy)}}</span>
            <span v-else-if="row.item.budgetBiasStrategy">{{$filters.labelizePacingStrategy(row.item.budgetBiasStrategy)}}</span>
            <span v-else>--</span>
          </template>

          <template v-slot:cell(useBroadMatchOnUnderspend)="row">
            <div class="d-flex-center-end">
              <b-checkbox v-if="row.field.option !== 'static' && hasCapability(row.item, 'UnderspendMitigation_matchType')"
                          :checked="row.item.useBroadMatchOnUnderspend"
                          switch
                          @change="row.item.useBroadMatchOnUnderspend = $event; $emit('saved', row.item)" />
              <span v-else class="mr-4">
                <template v-if="hasCapability(row.item, 'UnderspendMitigation_matchType')">
                  {{(row.item.useBroadMatchOnUnderspend)?'On':'Off'}}
                </template>
                <span v-else class="text-muted">&mdash;</span>
              </span>
            </div>
          </template>

          <template v-slot:cell(openAdSchedulesOnUnderspend)="row">
            <div class="d-flex-center-end">
              <b-checkbox v-if="row.field.option !== 'static' && hasCapability(row.item, 'UnderspendMitigation_adSchedule')"
                          :checked="row.item.openAdSchedulesOnUnderspend"
                          switch
                          @change="row.item.openAdSchedulesOnUnderspend = $event; $emit('saved', row.item)" />
              <span v-else class="mr-4">
                <template v-if="hasCapability(row.item, 'UnderspendMitigation_adSchedule')">
                  {{(row.item.openAdSchedulesOnUnderspend)?'On':'Off'}}
                </template>
                <span v-else class="text-muted">&mdash;</span>
              </span>
            </div>
          </template>

          <template v-slot:cell(dashboardLink)="row">
            <div v-if="row.item.budget || row.item.impressions"
                class="d-flex-center-end line-height-1">
              <nuxt-link :to="{path: '/dashboard/', query: {accountPlanId: row.item.accountPlanId}}"
                        target="_blank">
                <fluency-icon type="dashboard"
                              class="text-black-50"
                              size="lg" />
              </nuxt-link>
            </div>
          </template>

          <template v-slot:cell(contentTemplateId)="row">
            <div class="d-flex align-items-center justify-content-end">
              <div v-if="hoveredRowMap[row.item[getTableRowKey()]] && !isSubTable" class="table-action-container mr-2">
                <popover-cancel-save :value="row.item.contentTemplateId"
                                     :use-table-action-container="false"
                                     title="Manage Blueprint Link"
                                     triggers="click"
                                     @save="$emit('save-strategy', {...row.item, contentTemplateId: $event})"
                                     :disable-editing="tableType !== 'campaign' || (row.field.option && row.field.option === 'static' || !row.item.contentTemplateId)">
                  <template #edit="{ valueRef, update }">
                    <div>
                      <label class="mb-0">
                        Blueprint Name
                      </label>
                      <div class="mad-lib-container mb-3">
                        <basic-mad-lib-input :value="valueRef"
                                             @input="update"
                                             require-option
                                             :type-ahead-options="row.field.option"
                                              disabled/>
                      </div>
                      <t-button-inline v-if="valueRef" severity="secondary" class="ml mb-3" @click.stop="update('Unlinked')">
                        Unlink Blueprint
                        <fluency-icon type="linkBroken"></fluency-icon>
                      </t-button-inline>
                      <div class="text-muted">
                        Unlinking this campaign will disconnect it from the Blueprint. This action does not permanently
                        remove the link, as it is still possible for the Blueprint to takeover this campaign again if
                        it matches on name or formula.
                      </div>
                    </div>
                  </template>
                </popover-cancel-save>
                <t-button-inline v-if="row.item.contentTemplateId"
                                 severity="secondary" size="small"
                                 href="#blueprint-previewer-open"
                                 v-p-tooltip.top="{ value: `Preview ${(blueprints[row.item.contentTemplateId]) ? blueprints[row.item.contentTemplateId].templateName : 'Unresolved Blueprint'}`, showDelay: 1000 }"
                                 @click.prevent="previewBlueprint(row)">
                  <fluency-icon type="search" />
                </t-button-inline>
              </div>
              <small class="text-truncate mr-2" v-if="row.item.contentTemplateId && blueprints[row.item.contentTemplateId]">{{blueprints[row.item.contentTemplateId].templateName}}</small>
              <template v-if="row.item.contentTemplateId">
                <img v-if="activelyRepublishingBlueprints.includes(row.item.contentTemplateId)"
                    src="core-ui/assets/img/robots/full-flashing.gif"
                    width="20"
                    height="25"
                    v-p-tooltip.top="{ value: 'Republishing...', showDelay: 1000 }" />
                <img v-else
                    src="core-ui/assets/img/robots/full-white.png"
                    width="20"
                    height="25"
                    v-p-tooltip.top="{ value: `Linked to: ${(blueprints[row.item.contentTemplateId]) ? blueprintTooltip(row.item) : 'Unresolved Blueprint'}`, showDelay: 1000 }" />
              </template>
              <span v-else
                    class="text-muted"
                    v-p-tooltip.top="{ value: 'Not linked to any blueprint.', showDelay: 1000 }">&mdash;</span>
            </div>
          </template>

          <template v-slot:cell(geoTargetExpansionAbsoluteMaxInMiles)="row">
            <div class="d-flex justify-content-end align-items-center inline-edit">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]] && (hasCapability(row.item, 'UnderspendMitigation') || hasCapability(row.item, 'UnderspendGeoTargetMitigationOnly'))"
                                   title="Update Geo Max Expansion"
                                  :value="row.item.geoTargetExpansionAbsoluteMaxInMiles"
                                  @save="$emit('saved', {...row.item, geoTargetExpansionAbsoluteMaxInMiles: $event})"
                                  :disable-editing="(row.field.option && row.field.option === 'static')">

                <template #edit="{value, update}">
                  <label class="mb-0">Geo Max Expansion</label>
                  <div class="mad-lib-container">
                    <basic-mad-lib-input :value="value" format="NUMBER" @input="update"></basic-mad-lib-input>
                    <span class="ml-3">mi</span>
                    <t-button-inline v-if="value" severity="secondary" class="ml-3" @click="update(0)">
                      <fluency-icon type="delete"></fluency-icon>
                    </t-button-inline>
                  </div>
                  <div class="text-muted">Apply a new radius target for any campaign linked to this budget while this underspend mitigation feature is active. Ensure that this new radius is larger than your existing targets.</div>
                </template>
              </popover-cancel-save>
              <span class="pl-2">{{row.item.geoTargetExpansionAbsoluteMaxInMiles}} mi</span>
            </div>
          </template>

          <template v-slot:cell(bidAdjustmentPercentageOnUnderspend)="row">
            <div class="d-flex justify-content-end align-items-center inline-edit">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]] && hasCapability(row.item, 'UnderspendMitigation')"
                                  class="mr-2"
                                   title="Make Bid Adjustment"
                                  :value="row.item.bidAdjustmentPercentageOnUnderspend"
                                  @save="$emit('saved', {...row.item, bidAdjustmentPercentageOnUnderspend: $event})"
                                  :disable-editing="(row.field.option && row.field.option === 'static')">

                <template #edit="{value, update}">
                  <label class="mb-0">Adjustment</label>
                  <div class="mad-lib-container">
                    <basic-mad-lib-input format="PERCENTAGE" :value="value" @input="update"></basic-mad-lib-input>
                  </div>
                  <div class="text-muted">
                    When your account is underspending, this tactic will add a bid adjustment to any keyword within the campaign linked to this budget. Note: Keywords using automated bidding strategies will not be impacted.
                  </div>
                </template>

              </popover-cancel-save>
              <template v-if="row.item.bidAdjustmentPercentageOnUnderspend !== 0 && row.item.bidAdjustmentPercentageOnUnderspend !== 1 && hasCapability(row.item, 'UnderspendMitigation')">
                +{{$filters.percentage(row.item.bidAdjustmentPercentageOnUnderspend - 1, 0)}}
              </template>
              <template v-else>
                &mdash;<span class="invisible">%</span>
              </template>
            </div>
          </template>

          <template v-slot:cell(contentTemplateNameFullText)="row">
            {{row.item.contentTemplateName}}
          </template>

          <template v-slot:cell(subscriptionPaused)="row">
            <b-checkbox :checked="row.item.paused"
                        switch
                        class="danger-toggles"
                        @change="row.item.paused = $event; $emit('saved', row.item)">
              <b v-if="row.item.paused" class="text-danger">FORCED Paused</b>
              <span v-else>Linked To Strategy</span>
            </b-checkbox>
          </template>

          <template v-slot:cell(broadMatchBid)="row">
            <keywords-edit-field
              v-if="hoveredRowMap[row.item[getTableRowKey()]] && !isSubTable"
              :keywordItem="row.item"
              field="broadMatchBid"
              label="Broad Match Bid"
              @save="$emit('saved', $event )"
            />
            <template
              v-if="row.item.broadMatchBid > 0 && !row.item.isEnhancedBroadMatch">
              {{$filters.currency(row.item.broadMatchBid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
            </template>
            <template v-else-if="row.item.broadMatchBid === -1 && (!row.item.hasOwnProperty('isEnhancedBroadMatch') || !row.item.isEnhancedBroadMatch)">(auto)</template>
            <template v-else>-</template>
          </template>

          <template v-slot:cell(isEnhancedBroadMatch)="row">
            <template v-if="row.item.broadMatchBid > 0 && row.item.hasOwnProperty('isEnhancedBroadMatch') && row.item.isEnhancedBroadMatch === true">
              {{$filters.currency(row.item.broadMatchBid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
            </template>
            <template v-else-if="row.item.broadMatchBid === -1 && (row.item.hasOwnProperty('isEnhancedBroadMatch') || row.item.isEnhancedBroadMatch)">(auto)</template>
            <template v-else>-</template>
          </template>

          <template v-slot:cell(newBroadMatchBid)="row">
            <template v-if="row.item.hasOwnProperty('newIsEnhancedBroadMatch')"> <!-- if newIsEnhancedBroadMatch is set -->
              <template
                v-if="row.item.newBroadMatchBid > 0 && !row.item.newIsEnhancedBroadMatch">
                {{$filters.currency(row.item.newBroadMatchBid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
              </template>
              <template v-else-if="row.item.newBroadMatchBid === -1 && !row.item.newIsEnhancedBroadMatch">(auto)</template>
              <template v-else>-</template>
            </template>
            <template v-else> <!-- if newIsEnhancedBroadMatch is not set, meaning no change-->
              <template
                v-if="row.item.newBroadMatchBid > 0 && !row.item.isEnhancedBroadMatch">
                {{$filters.currency(row.item.newBroadMatchBid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
              </template>
              <template v-else-if="row.item.newBroadMatchBid === -1 && !row.item.isEnhancedBroadMatch">(auto)</template>
              <template v-else>-</template>
            </template>
          </template>

          <template v-slot:cell(newIsEnhancedBroadMatch)="row">
            <template v-if="row.item.hasOwnProperty('newIsEnhancedBroadMatch')"> <!-- if newIsEnhancedBroadMatch is set -->
              <template
                v-if="row.item.newBroadMatchBid > 0 && row.item.newIsEnhancedBroadMatch === true">
                {{$filters.currency(row.item.newBroadMatchBid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
              </template>
              <template v-else-if="row.item.newBroadMatchBid === -1 && (row.item.hasOwnProperty('newIsEnhancedBroadMatch') || row.item.newIsEnhancedBroadMatch)">(auto)</template>
              <template v-else>-</template>
            </template>
            <template v-else>
              <template
                v-if="row.item.newBroadMatchBid > 0 && row.item.hasOwnProperty('isEnhancedBroadMatch') && row.item.isEnhancedBroadMatch === true">
                {{$filters.currency(row.item.newBroadMatchBid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
              </template>
              <template v-else-if="row.item.newBroadMatchBid === -1 && (row.item.hasOwnProperty('isEnhancedBroadMatch') || row.item.isEnhancedBroadMatch)">(auto)</template>
              <template v-else>-</template>
            </template>
          </template>

          <template v-slot:cell(exactMatchBid)="row">
            <keywords-edit-field
              v-if="hoveredRowMap[row.item[getTableRowKey()]] && !isSubTable"
              :keywordItem="row.item"
              field="exactMatchBid"
              label="Exact Match Bid"
              @save="$emit('saved', $event )"
            />
            <template v-if="row.item.exactMatchBid > 0">
              {{$filters.currency(row.item.exactMatchBid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
            </template>
            <template v-else-if="row.item.exactMatchBid === -1">(auto)</template>
            <template v-else>-</template>
          </template>

          <template v-slot:cell(phraseMatchBid)="row">
            <keywords-edit-field
              v-if="hoveredRowMap[row.item[getTableRowKey()]] && !isSubTable"
              :keywordItem="row.item"
              field="phraseMatchBid"
              label="Phrase Match Bid"
              @save="$emit('saved', $event )"
            />
            <template v-if="row.item.phraseMatchBid > 0">
              {{$filters.currency(row.item.phraseMatchBid, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
            </template>
            <template v-else-if="row.item.phraseMatchBid === -1">(auto)</template>
            <template v-else>-</template>
          </template>

          <template v-slot:cell(assignedUserName)="row">
            <a target="_blank"
                    :href="buildAssignedUserMailtoLink(row)"
                    v-p-tooltip.top="{ value: row.value, showDelay: 1000 }">{{row.value}}
            </a>
          </template>

          <template v-slot:cell(labels)="row">
            <labels-display :labels="row.item.labels"/>
          </template>

          <template v-slot:cell(budgetCampaignLabels)="row">
            <campaign-labels-table-cell v-bind="row"
                                        v-if="!isSubTable"
                                        :lazy-load-editor="true"
                                        :load-editor="hoveredRowMap[row.item[getTableRowKey()]]"
                                        @save="$emit('saved', { ...row.item, labels: $event } )"/>
          </template>

          <template v-slot:cell(campaignLabels)="row">
            <campaign-labels-table-cell v-bind="row"
                                        v-if="!isSubTable"
                                        :lazy-load-editor="true"
                                        :load-editor="hoveredRowMap[row.item[getTableRowKey()]]"
                                        @save="$emit('saved', { ...row.item, labels: $event } )"/>
          </template>

          <template v-slot:cell(adGroupLabels)="row">
            <campaign-labels-table-cell v-bind="row"
                                        v-if="!isSubTable"
                                        :lazy-load-editor="true"
                                        :load-editor="hoveredRowMap[row.item[getTableRowKey()]]"
                                        @save="$emit('saved', { ...row.item, labels: $event } )"/>
          </template>

          <template v-slot:cell(spendYesterday)="row">
            {{$filters.currency(row.item.spendYesterday, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
          </template>

          <template v-slot:cell(criterionType)="row">
            {{row.value}}
            <info-tooltip v-if="row.item.criteria && row.item.criteria[0] && row.item.criteria[0].lastError"
                          variant="danger"
                          icon="warning"
                          icon-classes="text-danger"
                          :title="row.item.criteria[0].lastError" />
          </template>

          <template v-slot:cell(criterionSpec)="row">
            <criterion-spec :criterion="row.item"></criterion-spec>
          </template>

          <template v-slot:cell(criterionExcluded)="row">
            <fluency-icon v-if="row.item.excluded" type="checkmark"></fluency-icon>
          </template>

          <template v-slot:cell(criterionBid)="row">
            <template v-if="(row.item.bid - 1) > 0">
              +{{$filters.percentage(row.item.bid - 1, 0)}}
            </template>
            <template v-else-if="row.item.bid >= 0">
              -{{$filters.percentage(1 - row.item.bid, 0)}}
            </template>
            <template v-else-if="row.item.bid === -1">(auto)</template>
            <template v-else>-</template>
          </template>

          <template v-slot:cell(optimizationScore)="row">
            <template v-if="row.item.optimizationScore">
              <div class="d-flex align-items-center justify-content-end">
                <div v-if="hoveredRowMap[row.item[getTableRowKey()]]" class="table-action-container mr-2">
                  <t-button-inline severity="secondary" size="small"
                          v-p-tooltip.top="'View Opportunities To Increase Optimization Score'"
                          @click.stop.prevent="$eventBus.$emit('open-opti-score-opps', { name: row.item.name, planType: 'campaign', optiScore: row.item.optimizationScore, planId: row.item.campaignPlanId })">
                    <fluency-icon type="arrowUp" />
                  </t-button-inline>
                </div>
                {{$filters.percentage(row.item.optimizationScore)}}
              </div>
            </template>

          </template>

          <template v-slot:cell(campaignChannel)="row">
            {{getAdvertisingChannelLabel(row.item.advertisingChannelId)}}
          </template>

          <template v-slot:cell(navigations)="row">
            <span v-if="row.item.navigations">{{row.item.navigations}}</span>
          </template>

          <template v-slot:cell(healthScore)="row">
            <template v-if="row.item.healthScore && (!row.item.partnerId || row.item.partnerId === 1)">
              <div class="d-flex align-items-center justify-content-end">
                <div v-if="hoveredRowMap[row.item[getTableRowKey()]]" class="table-action-container mr-2">
                  <t-button-inline severity="secondary" size="small"
                          v-p-tooltip.top="'View Opportunities To Increase Optimization Score'"
                          @click.stop.prevent="$eventBus.$emit('open-opti-score-opps', { optiScore: row.item.healthScore, planId: row.item.accountPlanId, name: row.item.name, planType: 'account' })">
                    <fluency-icon type="arrowUp" />
                  </t-button-inline>
                </div>
                <span>{{$filters.percentage(row.item.healthScore)}}</span>
              </div>
            </template>
          </template>

          <!-- --------------------------------------------------------------------------------------------------- -->
          <!-- -- TABLE FOOTERS ---------------------------------------------------------------------------------- -->
          <!-- --------------------------------------------------------------------------------------------------- -->
          <template v-if="!totalsProp && summableColumns.length > 0" v-slot:foot()="data">
            <template v-if="data.field.key === `${planType}Name` || data.field.key === `${planType}Plan` || data.field.key === 'name' || data.field.key === 'keywordText'">
              <div class="text-right font-base">
                <template v-if="footerColumnSum">
                  <t-button variant="link" class="p-0" @click="toggleSumsOrAverages">Average</t-button>
                  / <b>Total</b>
                </template>
                <template v-else>
                  <b>Average</b> /
                  <t-button variant="link" class="p-0" @click="toggleSumsOrAverages">Total</t-button>
                </template>
                :
              </div>
            </template>
            <template v-else-if="summableColumns.indexOf(data.field.key) > -1 && data.field.hasOwnProperty('summable')">
              <delta-cell :previous="totals.hasOwnProperty(data.field.key) ? totals[data.field.key].previousTotal : false"
                          :current="totals[data.field.key] ? totals[data.field.key].total : ''"
                          :type="data.field.key"
                          :formatter="data.field.formatter" />
            </template>
            <template v-else>
              &nbsp;
            </template>
          </template>

          <!--Custom Footer Enabled by passing total data from the BE through totalsProp-->
          <template v-if="totalsProp" #custom-foot="data">
            <expand-footer :table-data="data" :totals-data="totalsProp" />
          </template>

          <template v-slot:cell(approvedAccountBudget)="row">
            <div class="inline-edit d-flex justify-content-end align-items-center">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]] && tableActionsAllowed"
                                  class="d-inline"
                                   title="Edit Approved Account Budget"
                                  :value="row.item"
                                  triggers="click clickOff"
                                  @save="$emit('saved', $event)"
                                  ok-text="Apply"
                                  boundary="viewport"
                                  :width="(row.field.option === 'hideBudgetChanges')?'auto':'470px'"
                                  :disable-editing="(row.field.option && row.field.option === 'static')">
                <template #edit="{value, update}">
                  <account-view-budget :hide-budget-changes="row.field.option && row.field.option === 'hideBudgetChanges'"
                                      :value="value"
                                      :force-open-default="true"
                                      @input="update"/>
                </template>
              </popover-cancel-save>
              <div>
                <span class="inline-editable-value">{{$filters.currency(row.item.approvedAccountBudget, 2, { code: row.item.currencyCode || row.item._currencyCode })}}</span>
                <div v-if="row.item.accountBudgetAdjustment && row.item.accountBudgetAdjustment !== 0"
                     class="d-block font-weight-bold">
                <span :class="row.item.accountBudgetAdjustment > 0 ? 'text-success' : 'text-info'">
                  {{row.item.accountBudgetAdjustment > 0 ? '+' : ''}}{{$filters.currency(row.item.accountBudgetAdjustment, 2, { code: row.item.currencyCode || row.item._currencyCode })}}
                </span>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:cell(nextApprovedAccountBudget)="row">
            <div class="inline-edit d-flex justify-content-end">
              <popover-cancel-save v-if="hoveredRowMap[row.item[getTableRowKey()]] && tableActionsAllowed"
                                  class="d-inline"
                                   title="Update Next Month's Approved Account Budget"
                                   triggers="click clickOff"
                                  :value="row.item.nextApprovedAccountBudget"
                                  @save="$emit('saved', {...row.item, nextApprovedAccountBudget: $event})"
                                  ok-text="Apply"
                                  :disable-editing="(row.field.option && row.field.option === 'static')">
                <template #edit="{value, update}">
                  <label class="mb-0">Next Month Approved Account Budget</label>
                  <div class="mad-lib-container">
                    <basic-mad-lib-input :value="value" format="CURRENCY" :min-currency-value="0" @input="update($event)"></basic-mad-lib-input>
                    <t-button-inline v-if="value" severity="secondary" class="ml-3" @click="update(value = null)">
                      <fluency-icon type="delete"></fluency-icon>
                    </t-button-inline>
                  </div>
                </template>
              </popover-cancel-save>
              <span v-if="row.item.nextApprovedAccountBudget">{{$filters.currency(row.item.nextApprovedAccountBudget, 2, { code: row.item.currencyCode || row.item._currencyCode })}}</span>
              <span v-else-if="row.item.nextApprovedAccountBudget === 0">{{$filters.currency(row.item.nextApprovedAccountBudget, 2, { code: row.item.currencyCode || row.item._currencyCode })}}</span>
              <span v-else> </span>
            </div>
          </template>

          <template v-slot:cell(paceToMetricValue)="row">
            <span v-if="row.item.budgetManagerConfiguration && row.item.budgetManagerConfiguration.hasOwnProperty('paceToMetricValue')">
              {{$filters.number(row.item.budgetManagerConfiguration.paceToMetricValue, 0, true)}}
            </span>
            <span v-else> </span>
          </template>

          <template v-slot:cell(metricPacing)="row">
            <span v-if="row.item.budgetOptimizerStatusDTO && row.item.budgetOptimizerStatusDTO.hasOwnProperty('metricPacing')">
              {{$filters.percentage(row.item.budgetOptimizerStatusDTO.metricPacing, 0)}}
            </span>
            <span v-else> </span>
          </template>

          <template v-slot:cell(paceToMetric)="row">
            <span v-if="row.item.budgetManagerConfiguration && row.item.budgetManagerConfiguration.hasOwnProperty('paceToMetric')">
              {{cleanText(row.item.budgetManagerConfiguration.paceToMetric)}}
            </span>
            <span v-else> </span>
          </template>

          <!-- --------------------------------------------------------------------------------------------------- -->
          <!-- -- DYNAMIC TABLE CELLS ---------------------------------------------------------------------------- -->
          <!-- --------------------------------------------------------------------------------------------------- -->
          <template v-for="config of customFields" v-slot:[`head(${config.key})`]="dynamicHeader" :key="config.key + '-' + dynamicHeader.field.index">
            <div >
              <slot v-bind="dynamicHeader" :name="`head(${config.key})`">
                <info-tooltip v-if="dynamicHeader.field.infoTooltip" :title="dynamicHeader.field.infoTooltip" /> {{dynamicHeader.label}}
              </slot>
            </div>
            <column-resize-handle v-if="config.resize !== false"
                                    :key="config.key + '-resize-' + dynamicHeader.field.index"
                                    :column="dynamicHeader.field.slot"
                                    :columnKey="dynamicHeader.field.key"
                                    :columnWidth="computedColumnWidths[dynamicHeader.field.slot]"
                                    :table-type="tableType"
                                    @column-resized="handleColumnResized($event)"/>
          </template>

          <template v-for="config of customFields" v-slot:[`cell(${config.key})`]="dynamicRow" :key="`customFields-${config.key}`">
            <component v-bind="{...dynamicRow, ...config}"
                       v-if="config.component"
                      :is="config.component"
                      :row="dynamicRow"/>
            <slot v-else :name="`cell(${config.key})`" v-bind="dynamicRow">
              {{dynamicRow.value}}
            </slot>
          </template>

          <template v-for="config of customFields" v-slot:[`foot(${config.key})`]="dynamicFoot">
            <slot v-bind="dynamicFoot" :name="`foot(${config.key})`">
<!--              {{dynamicFoot.label}}-->
            </slot>
          </template>
          <!-- below is a special case for handling extension name-->
          <template v-slot:foot(extensionName)>
            <div class="text-right">
              <template v-if="footerColumnSum">
                <t-button variant="link" @click="toggleSumsOrAverages">Average</t-button>
                / <b>Total</b>
              </template>
              <template v-else>
                <b>Average</b> /
                <t-button variant="link" @click="toggleSumsOrAverages">Total</t-button>
              </template>
              :
            </div>
          </template>
        </new-table>

        <div v-if="!isSubTable" class="fluent-table-bottom-scrollbar-buffer"></div>
      </div>
      <p-paginator v-if="lazyItems && Object.values(lazyItems).length > 0 && ((filteredItemsCount === 0 && Object.values(lazyItems).length > perPage) || filteredItemsCount > perPage)"
                 :rows="perPage"
                  :totalRecords="filteredItemsCount > 0 ? filteredItemsCount : Object.keys(lazyItems).length"
                  v-model:first="currentPageFirstIndex"
                  @page="$emit('pageUpdated', $event)"/>
    </div>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
import PopoverCancelSave from '../common/popoverCancelSave'
import PartnerIcons from '@/components/common/partnerIcons'
import _camelCase from 'lodash.camelcase'
import _debounce from 'lodash.debounce'
import ContentFilter from '../common/forms/content-filter'
import ManageLockedFieldsMixin from '@/mixins/manageLockedFieldsMixin'
import ColumnResizeHandle from 'core-ui/components/common/columnResizeHandle.vue'
import { PartnerName } from '../../assets/js/constants'
import ColumnFilter from '@/components/test/columnFilter'
import supportedFields from '../table/sharedColumnDefinitions'
import ImportExcel from '../common/import/importExcel'
import TableAccountNameActions from '@/components/test/tableAccountNameActions'
import ExpandFooter from '@/components/test/expandFooter'
import LatchInfo2 from '@/components/test/latchInfo2'
import md5 from 'md5'
import { pacingColor, renderSparkLine } from '../../assets/js/utilities'
import { googleLinkStatus } from '@/assets/js/googleAccountUtils'
import ManageReportDefinitions from '@/components/pages/manage/manageReportDefinitions'
import { DOUBLE as DOUBLE_NULL } from '@/assets/js/constants/dbNulls.js'
import CampaignStartAndEndDates from '@/components/pages/manage/campaign/campaignStartAndEndDates.vue'
import KeywordsEditField from '@/components/pages/manage/keyword/keywordsEditField.vue'
import MadLibSelect from 'core-ui/components/inputs/madLibSelect'
import SavedSegmentClearer from '@/components/common/savedSegmentClearer.vue'
import EditPartnerName from '../common/editPartnerName.vue'
import CampaignLabelsTableCell from '../table/cells/campaignLabelsTableCell.vue'
import LinkGenerator from '../common/colab/linkGenerator.vue'
import AttachedFeeds from './attachedFeeds.vue'
import DeltaCell from 'core-ui/components/common/deltaCell.vue'
import AccountViewBudget from '../common/accountViewBudget.vue'
import NextMonthBudgetEditor from '../pages/manage/budget/nextMonthBudgetEditor.vue'
import EditCampaignGroup from '../pages/manage/campaign/editCampaignGroup.vue'
import SubTableAdvancedFiltering from './subTableAdvancedFiltering.vue'
import LabelsDisplay from '../common/labelsDisplay.vue'
import CriterionSpec from '../common/criterionSpec.vue'
import ViewProposal from '../common/viewProposal.vue'
import CurrencyInput from 'core-ui/components/inputs/currencyInput.vue'
import CampaignBudgetSelector from '../pages/manage/campaign/campaignBudgetSelector.vue'
import AdvancedFilters from './advancedFilters.vue'
import ColumnEdit from '../table/columnEdit.vue'
import UnderspendProtectionAlert from '../pages/manage/underspendProtectionAlert.vue'
import BidAdjustmentInput from 'core-ui/components/inputs/bidAdjustment'
import PercentageInput from 'core-ui/components/inputs/percentageInput.vue'
import TableBulkEditBar from './tableBulkEditBar.vue'
import BudgetAmountEditorV2 from '../pages/manage/budget/budgetAmountEditorV2.vue'
import BudgetChannelSelector from '../common/budgetChannelSelector.vue'
import AdPreview from 'core-ui/components/adPreviews/adPreview.vue'
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput.vue'
import { shallowRef } from 'vue'
import NewTable from 'core-ui/components/common/newTable.vue'
import { convertButtonVariant } from 'core-ui/primeVueBridge/convertBvToPrime'
import { useMusePanel } from 'core-ui/composables/useMusePanel'

export default {
  name: 'FluentTable',
  mixins: [ManageLockedFieldsMixin],
  components: {
    NewTable,
    SavedSegmentClearer,
    CampaignStartAndEndDates,
    // InlineCancelSave,
    ManageReportDefinitions,
    EditPartnerName,
    LatchInfo2,
    CampaignLabelsTableCell,
    ExpandFooter,
    ImportExcel,
    TableAccountNameActions,
    ColumnFilter,
    ColumnResizeHandle,
    ContentFilter,
    PartnerIcons,
    PopoverCancelSave,
    FluencyLoader,
    KeywordsEditField,
    MadLibSelect,
    LinkGenerator,
    AttachedFeeds,
    DeltaCell,
    AccountViewBudget,
    NextMonthBudgetEditor,
    EditCampaignGroup,
    SubTableAdvancedFiltering,
    LabelsDisplay,
    CriterionSpec,
    ViewProposal,
    CurrencyInput,
    CampaignBudgetSelector,
    AdvancedFilters,
    ColumnEdit,
    UnderspendProtectionAlert,
    BidAdjustmentInput,
    PercentageInput,
    TableBulkEditBar,
    BudgetAmountEditorV2,
    BudgetChannelSelector,
    AdPreview,
    BasicMadLibInput
  },
  props: {
    plan: String,
    planType: String,
    items: [Array],
    fields: {
      type: [Object, Array],
      default: () => []
    },
    filter: [String, RegExp], // filter string to roll your own filter. you can .sync this if you need a two-way prop
    sortBy: String, // set sortBy and sortDesc to preSort the table on load.
    sortDesc: Boolean,
    showDownloadButton: Boolean,
    columnSaveKey: String,
    columnFilterKey: String,
    showCreateNewAccount: Boolean,
    showBroadcastButton: Boolean,
    showIconText: Boolean,
    enableExcelImport: {
      type: Boolean,
      default: false
    },
    enableReportBtn: {
      type: Boolean,
      default: false
    },
    striped: {
      type: Boolean,
      default: true
    },
    fixedHeader: {
      type: Boolean,
      default: true
    },
    overflowVisible: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: true
    },
    tableClass: {
      type: String,
      default: ''
    },
    theadClass: {
      type: String,
      default: ''
    },
    tfootClass: {
      type: String,
      default: ''
    },
    useFlexClasses: {
      type: Boolean,
      default: true
    },
    widthStyle: { // can be used with useFlexClasses
      type: String,
      default: '100%'
    },
    preventDeselect: {
      type: Boolean,
      default: false
    },
    sortIconLeft: {
      type: Boolean,
      default: false
    },
    toolbarPosition: {
      type: String,
      default: 'right' // left is also valid
    },
    primaryKey: [String, Number],
    tableToolbar: Array,
    sortExtension: {
      type: Function // you can extend fluent sorter to provide sorting for custom fields not supported directly by fluent table
    },
    filterIgnoredFields: {
      type: Array,
      default: () => []
    },
    filterIncludedFields: {
      type: Array,
      default: () => []
    },
    perPage: {
      type: Number,
      default: 100
    },
    showFooter: {
      type: Boolean,
      default: false
    },
    showAiChatButton: {
      type: Boolean,
      default: false
    },
    advancedFilterData: {
      type: Object
    },
    summableColumns: {
      type: Array,
      default: () => []
    },
    colSpans: {
      type: Object,
      default: () => {
        return {
          underspend: {
            width: 75,
            fields: [
              'bidAdjustmentPercentageOnUnderspend',
              'autoRemoveBlockingKeywordOnUnderspend',
              'useBroadMatchOnUnderspend',
              'openAdSchedulesOnUnderspend',
              'geoTargetExpansionAbsoluteMaxInMiles'
            ]
          }
        }
      }
    },
    isSubTable: {
      type: Boolean,
      default: false
    },
    selectedRowOverride: {
      type: String
    },
    subTableClasses: {
      type: String,
      default: 'hide-select-row border-0 details-table'
    },
    sharedBudgetTotals: {
      type: Boolean,
      default: false
    },
    totalsProp: {
      type: Object
    },
    numericColumnFilters: {
      type: Boolean,
      default: false
    },
    disableableRows: {
      type: Boolean,
      default: true
    },
    downloadAppendFields: {
      type: Array,
      default: () => []
    },
    tableActionsAllowed: {
      type: Boolean,
      default: true
    },
    isInlineLoading: {
      type: Boolean,
      default: false
    },
    tableActionEllipses: {
      type: Boolean,
      default: false
    },
    emptyFilterPrompt: {
      type: Boolean,
      default: false
    },
    showHeadersWhenEmpty: {
      type: Boolean,
      default: false
    },
    maintainSelection: {
      type: Boolean,
      default: false
    },
    memoize: {
      type: Boolean,
      default: true
    },
    textFilter: {
      type: String
    },
    startingPage: {
      type: Number
    },
    briefSelectionText: {
      type: Boolean,
      default: false
    }
  },
  compatConfig: {
    INSTANCE_LISTENERS: false,
    INSTANCE_SCOPED_SLOTS: false
  },
  setup () {
    const { addDataCollector, removeDataCollector } = useMusePanel()
    return {
      lazyItems: shallowRef([]),
      convertButtonVariant,
      addDataCollector,
      removeDataCollector
    }
  },
  data () {
    return {
      updateKey: 0,
      currentPageFirstIndex: this.startingPage || 0,
      filteredItems: [],
      isDeleteLocked: true,
      searchFilter: this.filter || '',
      sortTableBy: this.sortBy,
      sortTableDesc: this.sortDesc,
      showOnlyGuardrails: false,
      supportedFields,
      footerColumnSum: true,
      showAdvancedFiltering: false,
      isShiftEnabled: false,
      hoveredRowMap: { },
      defaultFieldKeys: [],
      defaultColumnWidthMap: {},
      computedColumnWidths: {},
      enhancedColumnFieldDefinitions: [],
      previouslySelectedRowIndex: 0,
      blueprints: {},
      tablePartners: [],
      tablePartnersFilter: [],
      rowHoverTimeout: null,
      totals: {},
      latchDialog: {
        show: false,
        index: null,
        accountPlanId: null,
        campaignPlanId: null,
        latches: []
      },
      numActiveFilters: 0,
      statusStaticDisplayInfo: {
        REMOVED: { variant: 'outline-danger', icon: 'stop', titleCallback: (tableType) => `${tableType} is removed and cannot be re-activated` },
        PAUSED: { variant: 'outline-warning', icon: 'pause', titleCallback: (tableType) => `${tableType} is Paused` },
        ENABLED: { variant: 'outline-success', icon: 'play', titleCallback: (tableType) => `${tableType} is Enabled` }
      },
      showEditPartnerName: -1,
      searchableFields: [],
      lastTableContext: undefined,
      inlineColumnsOpen: false,
      selectedRowIds: [],
      DOUBLE_NULL,
      subFields: [],
      subFieldKeyCounter: 0,
      columnEditPopoverShow: false
    }
  },
  watch: {
    items: {
      handler (val) {
        if (val && !this.isSubTable) {
          // val.forEach((item) => {
          //   this.hoveredRowMap[item[rthis.getTableRowKey()]] = true // with vue3, we don't need hoveredRowMap or lazy tooltips anymore - this is a bandaid until we take the time to remove it
          // })
          this.sortAndFilter(this.columnFilters, val)
        } else if (val) {
          this.lazyItems = val
        }
      },
      immediate: true
    },
    columnFilters: {
      handler (val) {
        if (!this.isSubTable) {
          this.selectedRowIds = []
          this.sortAndFilter(val)
        }
      },
      immediate: true
    },
    totalNumberOfTableItems () {
      if (!this.startingPage) this.currentPageFirstIndex = 0
    },
    planType () { // request to reset filter when plan type changes so that when you change tabs to a new section in manage the filter string resets
      this.searchFilter = ''
      this.$emit('update:filter', '')
    },
    fields: {
      async handler (newVal, oldVal) {
        await this.loadTableUserPrefs()
      },
      immediate: true,
      deep: true
    },
    sortBy (newVal) {
      this.sortTableBy = newVal
    },
    sortDesc (newVal) {
      this.sortTableDesc = newVal
    },
    filter (newVal) {
      this.searchFilter = newVal
    },
    searchFilter: _debounce(function (newVal) {
      this.$emit('update:filter', newVal)
      this.executeFilter(newVal, this.items, true)
    }, 300),
    lazyItems () {
      this.calcTotals()
    },
    selectedRowIds: {
      deep: true,
      handler (val) {
        if (this.items?.length > 0) {
          const rows = val.map(id => this.selectableItemsLookup[id]).filter(item => item)
          this.$store.commit('table/selectedRowObjs', { type: this.selectedRowKey, rows })
        }
      }
    },
    enhancedColumnFieldDefinitions: {
      immediate: true,
      deep: true,
      handler () {
        this.calcSubFields()
      }
    },
    showAiChatButton: {
      handler (value) {
        if (value) {
          this.addDataCollector(this.getCsvData)
        } else {
          this.removeDataCollector(this.getCsvData)
        }
      },
      immediate: true
    }
  },
  computed: {
    memoizeProps () {
      return JSON.stringify([this.striped, this.hover, this.totalsProp, this.footClone, this.primaryKey, this.tableClass, this.theadClass, this.tbodyTrClasses, this.enhancedColumnFieldDefinitions, this.sortTableBy, this.sortTableDesc])
    },
    pagedItems () {
      let start = this.currentPageFirstIndex
      let end = start + this.perPage
      const total = this.lazyItems.length
      if (start > total) {
        start = total - this.perPage
      }
      if (end > total) {
        end = total
      }
      return this.lazyItems.slice(start, end)
    },
    reportStillProcessing () {
      const response = this.$store.getters.reportRequestResponse
      if (response) {
        return !response.complete && !response.base64EncodedContents
      }
      return false
    },
    isInternalUser () {
      return this.$store.getters?.user?.internalUser
    },
    colabUser () {
      return this.$store.getters.user?.capabilities?.CoLab
    },
    AIChat () {
      return this.$store.getters?.user?.capabilities?.AIChat
    },
    activelyRepublishingBlueprints () {
      return this.$store.getters.activelyRepublishingBlueprints || {}
    },
    hasActiveChanneGroupFilter () {
      const channelGroupFilters = this.$store.getters.channelGroupsFilter

      return Array.isArray(channelGroupFilters) && channelGroupFilters.length > 0
    },
    columnFilters () {
      return this.$store.getters['table/columnFilters'](this.computedColumnFilterKey) || {}
    },
    selectedRowObjs () {
      return this.$store.getters['table/selectedRowObjs'](this.selectedRowKey) || []
    },
    appliedFiltersHash: {
      get () {
        return this.$store.getters['table/appliedFiltersHash']
      },
      set (val) {
        if (val !== this.appliedFiltersHash) {
          this.$store.commit('table/appliedFiltersHash', val)
        }
      }
    },
    isAdvancedMode () {
      return this.$store.getters.userMode === 'advanced'
    },
    budgetExplicitlyShared: {
      get () {
        return this.$store.getters.tableToggleFilters?.budget?.explicitlyShared
      },
      set (val) {
        this.$store.commit('setTableToggleFilters', { table: 'budget', toggle: 'explicitlyShared', value: val })
      }
    },
    budgetShowActiveOnly: {
      get () {
        return this.$store.getters.tableToggleFilters?.budget?.showActiveOnly
      },
      set (val) {
        this.$store.commit('setTableToggleFilters', { table: 'budget', toggle: 'showActiveOnly', value: val })
      }
    },
    campaignShowActiveOnly: {
      get () {
        return this.$store.getters.tableToggleFilters?.campaign?.showActiveOnly
      },
      set (val) {
        this.$store.commit('setTableToggleFilters', { table: 'campaign', toggle: 'showActiveOnly', value: val })
      }
    },
    comparableMetrics () {
      if (!this.lazyItems?.some(item => item.previousMetricsDTO)) {
        return []
      }
      if (!this.$store.getters.skeletons?.metrics) {
        return []
      }
      const metricSkeleton = this.$store.getters.skeletons.metrics
      delete metricSkeleton.appliedBudget
      delete metricSkeleton.qualityScore
      return Object.keys(metricSkeleton)
    },
    showPlanStatus () {
      return this.$store.getters.showPlanStatus
    },
    showRemoved: function () {
      return this.showPlanStatus === 'Show All'
    },
    totalNumberOfTableItems () {
      return (this.lazyItems) ? this.lazyItems.length : 0
    },
    advancedFiltersNotApplied () {
      if (this.advancedFilterData?.showOnlyErrors) {
        return false
      } else if (this.tableType === 'account' && this.$store.getters?.activeSegment && Object.keys(this.$store.getters.activeSegment).length > 0) {
        return false
      } else if (this.tableType === 'budget' && this.budgetExplicitlyShared) {
        return false
      } else if (Object.keys(this.columnFilters).length > 0) {
        return false
      }
      return true
    },
    computedColSpans () {
      const arr = []

      let counter = 0
      let match = ''

      this.enhancedColumnFieldDefinitions.forEach((field) => {
        const cols = Object.keys(this.colSpans)

        for (let i = 0; i < cols.length; i++) {
          const col = cols[i]

          if (this.colSpans[col].fields.indexOf(field.key) >= 0) {
            if (counter === 0) {
              match = field.key
            }

            counter++

            if (counter === 1) {
              arr.push({ key: match, colspan: counter, label: col })
            } else {
              arr.forEach((m) => {
                if (m.key === match) {
                  m.colspan = counter
                }
              })
            }
          } else {
            match = 0
            counter = 0

            arr.push({ key: field.key, colspan: 0, field: field })
          }
        }
      })
      return arr
    },
    footClone () {
      return this.showFooter || this.summableColumns.length > 0
    },
    guardrails () {
      return this.$store.getters.setting('showGuardrails')
    },
    whiteLabelClassName () {
      return this.$store.getters.whiteLabel.className
    },
    cFilterIgnoredFields () {
      if (this.filterIgnoredFields.length > 0) {
        return this.filterIgnoredFields
      }
      return []
    },
    cFilterIncludedFields () {
      let result = []
      switch (this.tableType) {
        case 'account':
          result = ['name', 'assignedUserName', 'labels']
          break
        case 'budget':
          result = ['name', 'campaignGroupName', 'budgetType', 'pacingStrategy']
          break
        case 'campaign':
          result = ['name', 'campaignGroupName']
          break
        case 'adGroup':
          result = ['name']
          break
        case 'keyword':
          result = ['keywordText']
          break
        case 'ad':
          result = ['name', 'adSpec']
          break
        case 'extension':
          result = ['siteLinkText']
          break
        case 'audience':
          result = ['userListPlanDTO-->name', 'userListPlanDTO-->description']
          break
        default:
          break
      }
      return result.concat(this.filterIncludedFields || [])
    },
    tableClasses () {
      let arr = []
      if (this.overflowVisible) {
        arr = arr.concat(['overflow-visible'])
      }
      if (this.fixedHeader) {
        arr = arr.concat(['table-scroll'])
      }
      arr.push(`table-${this.tableType}`)
      const hasStickyColumns = this.enhancedColumnFieldDefinitions.some(fieldDef => fieldDef.sticky)
      if (this.fixedHeader || hasStickyColumns) {
        arr.push('table-fixed-width')
      }
      if (this.$store.getters.setting('showGuardrails')) {
        arr.push('guardrails-active')
      }
      if (this.isInlineLoading) {
        arr.push('inline-loading')
      }

      return arr
    },
    tableType () {
      if (this.planType === 'creative') {
        return 'ad'
      }
      return this.planType
    },
    selectedRowKey () {
      if (this.selectedRowOverride) {
        return this.selectedRowOverride
      }
      return this.tableType
    },
    computedColumnFilterKey () {
      return this.columnFilterKey || this.tableType
    },
    showFilter () {
      return typeof this.filter === 'string' || !!this.$slots.filter
    },
    displayTableType () {
      return this.$filters.convertFromCamelCase(this.tableType)
    },
    showSubItemEditing () {
      // this.$store.getters.user?.capabilities?.NamingOverrides
      return this.$store.getters.user?.capabilities?.NamingOverrides === true && (this.tableType === 'account' || this.tableType === 'campaign' || this.tableType === 'adGroup')
    },
    groupedColumnsList () {
      const arr = []

      Object.values(this.colSpans).forEach(o => {
        if (o.hasOwnProperty('fields')) {
          o.fields.forEach(a => {
            arr.push(a)
          })
        }
      })

      return arr
    },
    customFields () {
      return this.enhancedColumnFieldDefinitions.filter(f => !f.useDefaultTemplate)
    },
    activeItem () {
      return this.$store.getters.activeItem
    },
    filteredItemsCount () {
      return this.filteredItems.length
    },
    advancedFilterOptions () {
      if (this.planType === 'account' && this.items?.length > 0) {
        return { primaryVertical: this.items.map(item => item.primaryVertical).distinct() }
      }
      return {}
    },
    selectableItemsLookup () {
      const tableItems = this.filteredItemsCount > 0 ? this.filteredItems : this.lazyItems
      const lookup = {}
      // doing one loop here since lazyItems can be big
      tableItems.forEach(item => {
        if (!this.disableableRows || !item._disableRowSelection) {
          lookup[item[this.getTableRowKey()]] = item
        }
      })
      return lookup
      // if (this.disableableRows) {
      //   tableItems = tableItems.filter(item => !item._disableRowSelection)
      // }
      // return tableItems.toMap(item => item[this.getTableRowKey()], item => item)
    },
    selectableKeys () {
      return Object.keys(this.selectableItemsLookup).map(k => parseInt(k))
    },
    numSelectableItems () {
      return this.selectableKeys.length
    },
    selectAllIndeterminate () {
      return this.selectedRowIds.length > 0 && this.selectedRowIds.length < this.numSelectableItems
    },
    isTableSelectable () {
      if (this.defaultFieldKeys.includes('selectRow')) {
        return true
      } else {
        return this.$attrs.selectable === true || this.$attrs.selectable === 'true'
      }
    },
    noSelectOnClick () {
      const val = this.$attrs['no-select-on-click']
      if (val === true || val === false) {
        return val
      }
      return true
    },
    tableSelectMode () {
      if (this.$attrs['select-mode']?.length > 0) {
        return this.$attrs['select-mode']
      }
      return 'multi'
    },
    isSingleSelectMode () {
      return this.tableSelectMode === 'single'
    },
    currentSortKey () {
      const sortBy = this.sortTableBy || 'none'
      const sortDir = this.sortTableDesc || 'none'
      return `${sortBy}-${sortDir}`
    },
    useNavBarMinHeight () {
      if (this.showFilter) {
        return true
      }
      if (this.isTableSelectable) {
        if (this.$slots['right-nav'] || this.$slots['left-nav']) {
          return true
        }
        if (this.tableToolbar) {
          return true
        }
        if (this.advancedFilterData || this.$slots.channelGroupFilter || this.tableType === 'budget') {
          return true
        }
        if (this.showCreateNewAccount || this.enableExcelImport || this.enableReportBtn || this.showBroadcastButton || this.parentHasReloadListener || this.showDownloadButton || this.columnSaveKey || this.$slots.addNewButton || this.parentHasAddListener) {
          return true
        }
      }
      if (this.enhancedColumnFieldDefinitions.some(def => def.filter)) {
        return true
      }
      return false
    //    isTableSelectable || showFilter
    },
    approvedBudgetPacing () {
      return this.$store.getters.user?.capabilities?.ApprovedBudgetPacing
    },
    customerHasFacebook () {
      return this.$store.getters.partners.some(p => p.partnerId === 3 && p.enabled)
    },
    parentHasReloadListener () {
      return this.$attrs.onReload && typeof this.$attrs.onReload === 'function'
    },
    parentHasAddListener () {
      return this.$attrs.onAdd && typeof this.$attrs.onAdd === 'function'
    }
  },
  methods: {
    blueprintTooltip (rowItem) {
      let output = this.blueprints[rowItem?.contentTemplateId]?.templateName

      if (rowItem?.validWhen) {
        output += `\n\nValid When:\n${rowItem.validWhen}`
      }

      return output
    },
    /* These are the old vue2 filters */
    dynamicFilter (inp, type = 'default') {
      if (isNaN(inp)) {
        return ' - '
      }

      switch (type) {
        case 'currency':
        case 'currency-precise':
          return this.$filters.currency(inp, 2)
        case 'percentage':
          return this.$filters.percentage(inp, 1)
        case 'number':
          return this.$filters.number(inp, 0, true)
        default:
          return inp
      }
    },
    planTypeLabel (str) {
      if (str && typeof str === 'string' && str !== '') {
        const updatedStr = str
          .replace('adgroup', 'Ad Group')
          .replace('creative', 'Ad')
          .replace('accountIngests', 'accountIngest')
        return (this.$filters.convertFromCamelCase(updatedStr) + 's').replace('Criterions', 'Criteria').replace('Reports', 'Report')
      }
      return str
    },
    momentFilter (time) {
      if (!time) {
        return null
      }
      return this.$moment(time).format('MM/DD/YYYY')
    },
    /* End of old filters */
    trackQuickActions (title, item) {
      this.$track.event('table action', {
        quickAction: title
      }, 'manage', item)
    },
    trackTableActions (event) {
      const item = event.target.closest('.dropdown-item')
      if (!item) return

      this.$track.event('table action', {
        quickAction: item.innerText
      }, 'manage', item)
    },
    randAnimationDelay () {
      const rand = Math.floor(Math.random() * 4)

      return `start-${rand}`
    },
    getCsvData () {
      if (!this.items) return
      const items = Object.values(this.lazyItems)
      const vthis = this
      // let csvContent = 'data:text/csv;charset=utf-8,'
      let csvContent = ''
      const columns = [...vthis.enhancedColumnFieldDefinitions, ...this.downloadAppendFields]
      const excludedFields = ['selectRow', 'dashboardLink', 'actions', ...columns.filter(c => c.hideFromDownload).map(c => c.key || c.slot)]
      // define headers
      for (let i = 0; i < columns.length; i++) {
        const field = columns[i]
        if (!excludedFields.includes(field.key)) {
          csvContent += ((field.label) ? field.label.replace(/\n/g, ' ') : this.convertFromCamelCase(field.key)) + ','
        }
      }
      // remove trailing comma
      csvContent = csvContent.replace(/,\s*$/, '')
      // add newline after header row
      csvContent += '\r\n'

      // loop through body and save
      items.forEach((itemArray, loopIndex) => {
        const rowArray = []

        for (let i = 0; i < columns.length; i++) {
          const key = columns[i].dataKey || columns[i].key
          let fieldValue = itemArray[key]
          let renderVal = fieldValue

          switch (key) {
            case 'score':
              renderVal = itemArray.grade
              break
            case 'accountPlanName':
              renderVal = itemArray?.accountPlan?.name || 'n/a'
              break
            case 'creativePlan':
              // get inner text from the table
              try {
                renderVal = JSON.stringify(itemArray.adSpec)
              } catch (e) {
                renderVal = '[Ad Preview]'
              }
              break
            case 'accountAppliedBudget':
              renderVal = fieldValue = this.$filters.currency(itemArray.budget)
              break
            case 'budgetSpendSoFar':
              renderVal = fieldValue = itemArray.budgetOptimizerStatusDTO?.spendSoFar?.map(spend => this.$filters.currency(spend, 0))
              break
            case 'budgetBiasStrategy':
              renderVal = fieldValue = itemArray.budgetManagerConfiguration?.budgetBiasStrategy
              break
            case 'camp':
              renderVal = fieldValue = itemArray.campaignPlans.filter(cmp => cmp).map(campaign => `${campaign.name} (${campaign.campaignPlanId})`).join(', ')
              break
            case 'channels':
              renderVal = fieldValue = itemArray.partners?.filter(partner => partner).map(partner => partner.name).join(', ')
              break
            case 'rationale':
              fieldValue = itemArray[key]
              renderVal = fieldValue = fieldValue.replace(/<(.|\n)*?>/g, '')
              break
            case 'gaViewId':
              renderVal = (itemArray.gaLinkId) ? 'linked' : 'unlinked'
              break
            case 'youtubeStatus':
              renderVal = (itemArray.youtubeChannelId) ? 'linked' : 'unlinked'
              break
            case 'googleContentStatus':
              renderVal = googleLinkStatus('Content', itemArray).badge.text
              break
            case 'googleMyBusinessStatus':
              renderVal = googleLinkStatus('MyBusiness', itemArray).badge.text
              break
            case 'facebookPageStatus':
              const facebookPageStatusInfo = this.$store.getters.facebookPageLinkStatusMap[itemArray.accountPlanId]
              if (facebookPageStatusInfo) {
                renderVal = facebookPageStatusInfo.facebookPageLinkStatus
              } else {
                renderVal = 'none'
              }
              break
            default:
              if (typeof fieldValue === 'object') {
                renderVal = JSON.stringify(fieldValue)
              }
              break
          }

          if (columns[i].formatter) {
            renderVal = columns[i].formatter(fieldValue, key, itemArray)
          }

          if (typeof renderVal === 'undefined') {
            renderVal = ''
          }
          if (!excludedFields.includes(columns[i].key)) {
            if (typeof renderVal === 'string') {
              renderVal = renderVal.replace(/"/g, '\'') // replace comma with pipe so that we don't bust the CSV on objects
            }
            rowArray.push('"' + renderVal + '"')
          }
        }
        csvContent += rowArray.join(',') + '\r\n'
      })
      return csvContent
    },
    executeFilter (val = this.searchFilter, updatedItems = this.items, apply = false) {
      let retVal = []
      if (val?.length > 0) {
        this.getSearchableFields(updatedItems)
        const localItems = []
        updatedItems?.forEach(item => {
          let pushIt = false
          this.searchableFields.every(itemKey => {
            if (item[itemKey] && item[itemKey].toLowerCase().indexOf(val.toLowerCase()) !== -1) {
              pushIt = true
              return false
            }

            // supports dot notation given the field and item
            if (itemKey.indexOf('-->') >= 0) {
              const splitStr = itemKey.split('-->')
              if (item?.[splitStr[0]]?.[splitStr[1]] && item?.[splitStr[0]]?.[splitStr[1]].toLowerCase().indexOf(val.toLowerCase()) !== -1) {
                pushIt = true
                return false
              }
            }
            return true
          })
          if (pushIt) {
            localItems.push(item)
          }
        })
        retVal = localItems
      } else {
        retVal = updatedItems
      }
      if (apply) {
        this.lazyItems = retVal
      }
      return retVal
    },
    getSearchableFields (updatedItems) {
      if (this.cFilterIncludedFields.length === 0 && this.searchableFields.length === 0 && updatedItems?.length > 0) {
        Object.keys(updatedItems[0]).forEach(itemKey => {
          if (typeof updatedItems[0][itemKey] === 'string') {
            this.searchableFields.push(itemKey)
          }
        })
      } else if (this.cFilterIncludedFields.length > 0 && this.searchableFields.length === 0) {
        this.searchableFields = this.cFilterIncludedFields
      }
    },
    doSort (tableContext, items = this.lazyItems) { // resort everything then hand the data back to the table
      const field = this.enhancedColumnFieldDefinitions.find(c => c.slot === tableContext.sortBy || c.key === tableContext.sortBy)
      if (typeof field?.sortByFormatted === 'function') {
        tableContext.formatter = field.sortByFormatted
      } else if (field?.sortByFormatted === true) {
        tableContext.formatter = field.formatter
      }
      this.lastTableContext = { ...tableContext } // save this in case items update
      if (tableContext.sortDesc) {
        items = Array.from(items).sort((a, b) => this.fluentSorter(b, a, tableContext.sortBy, tableContext.sortDesc, tableContext.formatter, { numeric: true }))
      } else {
        items = Array.from(items).sort((a, b) => this.fluentSorter(a, b, tableContext.sortBy, tableContext.sortDesc, tableContext.formatter, { numeric: true }))
      }
      return items
    },
    openDrawer (name = null, el = null) {
      if (name && el) {
        this.$emit(`${name}-drawer-pop`, el)
      } else {
        this.$eventBus.$emit('manage-drawer-open')
      }
    },
    openAnalyzeCampaign (campaignPlan, event) {
      this.trackQuickActions('Analyze Campaign', event.target.closest('.action-button'))
      this.$emit('open-analyze-campaign', campaignPlan)
    },
    openPreviewGeos (campaignPlan, event) {
      this.trackQuickActions('Preview Geos', event.target.closest('.action-button'))
      this.$emit('open-preview-geos', campaignPlan)
    },
    viewSimilarMetrics (campaignPlan, event) {
      this.trackQuickActions('View Similar Metrics', event.target.closest('.action-button'))
      this.$emit('viewSimilarMetrics', campaignPlan)
    },
    openDrawerQuick (campaignPlan, event) {
      this.trackQuickActions('Open Settings', event.target.closest('.action-button'))
      this.openDrawer('settings-clicked', campaignPlan)
    },
    showProductCatalogPreview (item, e) {
      this.$emit('show-product-catalog-preview', { name: item.name, productCatalogPreview: item.productCatalogPreview })
    },
    previewBlueprint (row) {
      if (row?.item) {
        this.$eventBus.$emit('blueprint-previewer-open', row.item)
      }
    },
    setNumActiveFilters (e) {
      this.numActiveFilters = e
    },
    hasCapability (item, capability) {
      if (item?.capabilities?.[capability]) {
        return true
      }
      return false
    },
    showTodaysTargetEditing (item) {
      if (this.isSubTable) {
        const budgetPlan = this.$store.getters.activeAccountBudgets.find(bud => bud.budgetPlanId === item.budgetPlanId)
        if (budgetPlan) {
          return this.hasCapability(budgetPlan, 'ExternalBudgets') && budgetPlan.budgetManagerConfiguration?.budgetBiasStrategy === 'EXTERNAL'
        }
      }
      return false
    },
    showLatchDialog (show, campaignPlanId, accountPlanId, latches) {
      const latchDialog = {
        show,
        campaignPlanId,
        accountPlanId,
        latches
      }
      this.latchDialog = latchDialog
    },
    sortAndFilter (val, updatedItems = this.items) {
      if (!updatedItems) return
      let itemsToFilter = this.searchFilter?.length > 0 ? this.executeFilter(this.searchFilter, [...updatedItems]) : [...updatedItems]
      if (this.lastTableContext) {
        itemsToFilter = this.doSort(this.lastTableContext, itemsToFilter)
      } else if (this.sortBy && typeof this.sortDesc === 'boolean') {
        // sorting on initial load if sort props are passed in
        itemsToFilter = this.doSort({ sortBy: this.sortBy, sortDesc: this.sortDesc }, itemsToFilter)
      }
      if (Object.keys(val).length > 0) {
        // adding metric start/end date to filter hash to ensure items update when date range changes
        // getting dates off items instead of vuex bc this runs a few times between date input and items changing - JC
        const metricsStartDate = itemsToFilter.find(item => item.metricsStartDate)?.metricsStartDate
        const metricsEndDate = itemsToFilter.find(item => item.metricsEndDate)?.metricsEndDate
        const filtersHash = md5(`${this.tableType}-${JSON.stringify(val)}-${itemsToFilter.length}-${metricsStartDate}-${metricsEndDate}`)
        if (filtersHash !== this.appliedFiltersHash) {
          let items = itemsToFilter
          for (const [key, filter] of Object.entries(val)) {
            const filterFunction = this.enhancedColumnFieldDefinitions.find(c => c.slot === key || c.key === key)?.filterFunction || this.genericFilterFunction
            const dataKey = supportedFields?.[key]?.dataKey || key
            items = filterFunction(items, filter, dataKey)
          }
          this.lazyItems = items
          this.appliedFiltersHash = filtersHash
        }
      } else if (itemsToFilter) {
        this.lazyItems = itemsToFilter
        this.appliedFiltersHash = `${this.tableType}`
      }
    },
    cleanText (text) {
      if (typeof text === 'string') {
        return this.$filters.convertFromCapsUnderscore(this.$filters.convertFromCamelCaseToCapsUnderscore(text))
      }
      return text
    },
    genericFilterFunction (items, filter, key) {
      if (!items) {
        return items
      }
      const keyOverride = supportedFields?.[key]?.dataKey || key
      let filteredItems = items
      const filterFormatter = (item) => filter.filterByFormatted ? filter.filterByFormatted(item[keyOverride], keyOverride, item) : item[keyOverride]

      if (filter.text && filter.text !== '') {
        filteredItems = filteredItems.filter(item => filterFormatter(item)?.toLowerCase().includes(filter.text.toLowerCase()) ?? true)
      }
      if (filter.checkboxValues && filter.checkboxValues.length > 0) {
        filteredItems = filteredItems.filter(item => filter.checkboxValues.includes(filterFormatter(item)) ||
          (filter.checkboxValues.includes('No Value') && typeof item[keyOverride] === 'undefined'))
      }
      if (!isNaN(filter.equals)) {
        filteredItems = filteredItems.filter(item => filterFormatter(item) === parseFloat(filter.equals))
      }
      if (!isNaN(filter.greaterThan)) {
        filteredItems = filteredItems.filter(item => filterFormatter(item) > parseFloat(filter.greaterThan))
      }
      if (!isNaN(filter.lessThan)) {
        filteredItems = filteredItems.filter(item => filterFormatter(item) < parseFloat(filter.lessThan))
      }
      return filteredItems
    },
    getPartnerLabel (id) {
      const name = PartnerName(id)
      return name === 'Partner' ? id : name
    },
    columnFilterSorter (field, desc) {
      let formatter
      if (typeof field.sortByFormatted === 'function') {
        formatter = field.sortByFormatted
      } else if (field.sortByFormatted === true) {
        formatter = field.formatter
      }
      this.lazyItems = this.doSort({ sortBy: field.key, sortDesc: desc, formatter })
    },
    showBudgetAmountSaveBtn (item) {
      const budgetBiasStrategy = item?.budgetManagerConfiguration?.budgetBiasStrategy || item?.budgetBiasStrategy
      if (!budgetBiasStrategy || budgetBiasStrategy.includes('SEGMENT')) {
        return false
      }
      return true
    },
    getTableRowKey () {
      return this.primaryKey
    },
    tbodyTrClasses (item) {
      let trClassesFromAttr = this.$attrs['tbody-tr-class'] || this.$attrs.tbodyTrClass || []
      if (typeof trClassesFromAttr === 'function') {
        trClassesFromAttr = trClassesFromAttr(item)
      }
      if (!Array.isArray(trClassesFromAttr)) {
        trClassesFromAttr = [trClassesFromAttr]
      }

      // mute the row for budgets that have no active campaigns -SNG
      // mute the row if something is paused or removed
      if (
        (item?.status && (!['ENHANCED', 'ENABLED', 'PENDING'].includes(item.status)) && !item?.removedFromFluency) ||
        (item?.campaignPlans && item.campaignPlans.length === 0) ||
        item?.servingStatus?.shortLabel === '$0 Budget'
      ) {
        trClassesFromAttr.push('text-muted')
      }

      return ['text-right', ...trClassesFromAttr]
    },
    budgetAmount (val) {
      if (val === 0) {
        return '<span class="text-muted font-weight-bold">HOLD</span>'
      } else {
        return this.$filters.currency(val, 2)
      }
    },
    nextBudgetAmount (val) {
      if (typeof val === 'undefined') {
        return ' '
      } else {
        return this.$filters.currency(val)
      }
    },
    optimizedDailyAmount (val) {
      if (typeof val === 'undefined') {
        return ' '
      } else {
        return this.$filters.currency(val, 2)
      }
    },
    toggleSumsOrAverages () {
      this.footerColumnSum = !this.footerColumnSum
      this.calcTotals()
    },
    summableDivider (a, b, previous = false) {
      let aTotal = 0
      let bTotal = 0

      this.lazyItems.forEach(item => {
        const metricDto = previous && item.previousMetricsDTO ? item.previousMetricsDTO : item

        aTotal += parseFloat(metricDto[a]) || 0
        bTotal += parseFloat(metricDto[b]) || 0
      })

      if (bTotal === 0) return 0

      return aTotal / bTotal
    },
    columnTotal (columnKey, previous = false) {
      if (columnKey === 'clickThroughRate') {
        return this.summableDivider('clicks', 'impressions', previous)
      }
      if (columnKey === 'costPerClick') {
        return this.summableDivider('cost', 'clicks', previous)
      }
      if (columnKey === 'conversionRate') {
        return this.summableDivider('conversions', 'clicks', previous)
      }
      if (columnKey === 'costPerConversion') {
        return this.summableDivider('cost', 'conversions', previous)
      }
      if (columnKey === 'costPerLead') {
        return this.summableDivider('cost', 'leads', previous)
      }
      if (columnKey === 'costPerThousandImpressions') {
        return 1000 * this.summableDivider('cost', 'impressions', previous)
      }
      if (columnKey === 'costPerThousandReach') {
        return 1000 * this.summableDivider('cost', 'reach', previous)
      }

      let totalSum = 0

      if (columnKey === 'budget' && this.sharedBudgetTotals) {
        const totaledSharedBudgets = []
        this.lazyItems.forEach(item => {
          if (!(item.budgetPlan?.budgetPlanId && totaledSharedBudgets.includes(item.budgetPlan.budgetPlanId)) && item.status === 'ENABLED') {
            totalSum += parseFloat(isNaN(item[columnKey]) ? 0 : item[columnKey])
          }
          if (item.budgetPlan?.explicitlyShared) totaledSharedBudgets.push(item.budgetPlan.budgetPlanId)
        })

        if (previous) { totalSum = null }
      } else if (columnKey === 'budgetAmount' && this.lazyItems[0] && this.lazyItems[0].campaignPlans) {
        this.lazyItems.forEach(item => {
          if (item.campaignPlans && item.campaignPlans.length > 0) {
            // using budget instead of budgetAmount here to include things like proration
            totalSum += parseFloat(isNaN(item.budget) ? 0 : item.budget)
          }
        })

        if (previous) { totalSum = null }
      } else {
        this.lazyItems.forEach(item => {
          const el = (previous) ? item?.previousMetricsDTO : item
          totalSum += parseFloat(isNaN(el?.[columnKey]) ? 0 : el[columnKey])
        })
      }
      return totalSum
    },
    columnAverage (columnKey, canBeTotaled, previous = false) {
      if (this.footerColumnSum && canBeTotaled) {
        return this.columnTotal(columnKey, previous)
      } else if (this.footerColumnSum) {
        return ' - '
      } else {
        return this.columnTotal(columnKey, previous) / this.lazyItems.length
      }
    },
    calcTotals () {
      if (!this.enhancedColumnFieldDefinitions || !this.lazyItems || this.totalsProp || !this.footClone) {
        return
      }
      // this.enhancedColumnFieldDefinitions && this.lazyItems &&
      this.enhancedColumnFieldDefinitions.forEach(field => {
        if (this.summableColumns.indexOf(field.key) > -1 && field.hasOwnProperty('summable')) {
          const total = this.columnAverage((field.summable.hasOwnProperty('fieldname')) ? field.summable.fieldname : field.key, field.summable.sum)
          const totalFormatted = this.dynamicFilter(total, field.summable.format)
          const previousTotal = this.columnAverage((field.summable.hasOwnProperty('fieldname')) ? field.summable.fieldname : field.key, field.summable.sum, true)
          const previousTotalFormatted = this.dynamicFilter(previousTotal, field.summable.format)

          // this.totals[field.key] = formattedTotal
          this.totals[field.key] = {
            total,
            totalFormatted,
            previousTotal,
            previousTotalFormatted
          }
        }
      })
    },
    outOfBoundsClass (item) {
      if (item) {
        return true
      }
      return ''
    },
    returnPartnerForType (item) {
      if (this.isSubTable) {
        return [item]
      }
      switch (this.tableType) {
        case 'account':
          return (item.subAccounts || [])
        case 'keyword':
          if (item.keywordId) {
            return [item]
          }
          return (item.partnerObjects || [])
        case 'budget':
          return (item.partnerObjects || [])
        default:
          return (item.partnerObjects || [])
      }
    },
    renderSparkLine,
    pacingColor,
    rowDetailsToggled (row, fromBulkBar) {
      const toggleItems = row ? [row.item] : []

      if (row) {
        row.toggleDetails()
      }

      if (fromBulkBar) {
        // Only toggle items in paginated table view - speed this up using IDs
        const itemsOnPage = this.pagedItems.filter((selected) => {
          return this.selectedRowObjs.some((item) => JSON.stringify(item) === JSON.stringify(selected))
        })

        if (itemsOnPage?.length > 0) {
          const hasCollapsedRow = itemsOnPage.filter(row => row._showDetails === false)?.length > 0
          itemsOnPage.forEach(item => {
            // if any are collapsed, EXPAND THEM ALL! otherwise collapse them all.
            if (!row || item[this.getTableRowKey()] !== row.item[this.getTableRowKey()]) {
              item._showDetails = hasCollapsedRow
              this.updateKey++
              toggleItems.push(item)
            }
          })
        }
      }

      if (toggleItems.length > 0) {
        this.$emit('row-details-toggled', toggleItems)
      }
    },
    convertToCamelCase (str) {
      return _camelCase(str)
    },
    createNewAccount () {
      this.$emit('createNewAccount')
    },
    downloadTable () {
      const csvContent = this.getCsvData()
      if (!csvContent) return
      let csvData = new Blob([csvContent], { type: 'text/csv' })
      const csvUrl = URL.createObjectURL(csvData)
      const link = document.createElement('a')
      link.setAttribute('href', csvUrl)
      link.setAttribute('download', 'export.csv')
      link.click()

      link.remove()
      URL.revokeObjectURL(csvUrl)
      csvData = null
    },
    launchAction (emitAction, rowItem) {
      this.$eventBus.$emit(emitAction, rowItem)
      this.$emit(emitAction, rowItem)
    },
    saveStatus (item, status) {
      if (status !== item.status) {
        this.$emit('save-status', { item, status })
      }
    },
    deleteItem (item) {
      if (!this.isDeleteLocked) {
        this.$emit('deleted', item)
        this.isDeleteLocked = true
      }
    },
    fluentSorter (a, b, key, sortDesc, formatter, compareOptions, compareLocale) {
      let c = ''
      let d = ''
      switch (key) {
        case 'stat':
        case 'status':
          if (a.status === b.status) return 0
          if (a.status === 'ENABLED') return -1
          if (a.status === 'REMOVED') return 1
          if (b.status === 'ENABLED') return 1
          if (b.status === 'REMOVED') return -1
          return 0 // logically, i don't think this will hit
        case 'selectRow':
          const aSelected = this.selectedRowIds.includes(a[this.getTableRowKey()])
          const bSelected = this.selectedRowIds.includes(b[this.getTableRowKey()])
          if (aSelected === bSelected) return 0
          if (aSelected) return -1
          if (bSelected) return 1
          return 0 // logically, i don't think this will hit
        // update sorting for fields where we aren't using standard key mappings from the data to the fluency table key ex budgetName vs name
        case 'budgetName':
          c = a?.name ?? ''
          d = b?.name ?? ''
          return this.defaultSort(c, d)
        case 'budgetCurrentTotalSpend': // row.item.budgetOptimizerStatusDTO.currentTotalSpend
          c = a.budgetOptimizerStatusDTO?.currentTotalSpend ?? 0
          d = b.budgetOptimizerStatusDTO?.currentTotalSpend ?? 0
          return this.defaultSort(c, d)
        case 'accountAppliedBudget':
          c = a.budget
          d = b.budget
          return this.defaultSort(c, d)
        case 'budgetChannel':
        case 'campaignChannel': // need to lookup channel label from channel id
          const aAdvertisingChannel = (this.$store.getters.advertisingChannels.find(x => x.advertisingChannelId === a.advertisingChannelId))
          const bAdvertisingChannel = (this.$store.getters.advertisingChannels.find(x => x.advertisingChannelId === b.advertisingChannelId))
          if (aAdvertisingChannel && bAdvertisingChannel) {
            c = aAdvertisingChannel.channelLabel
            d = bAdvertisingChannel.channelLabel
            return this.defaultSort(c, d)
          } else {
            return false
          }
        case 'budgetType':
          if (a.budgetType || b.budgetType) {
            return this.defaultSort(a.budgetType, b.budgetType)
          } else if (a.budgetPlan?.budgetType || b.budgetPlan?.budgetType) {
            return this.defaultSort(a.budgetPlan?.budgetType, b.budgetPlan?.budgetType)
          }
          return false
        case 'channels':
          c = this.returnPartnerForType(a).map(p => p.partnerId).join('.')
          d = this.returnPartnerForType(b).map(p => p.partnerId).join('.')
          return this.defaultSort(c, d)
        case 'customName':
          return this.defaultSort(a, b, 'name', sortDesc, formatter, compareOptions, compareLocale)
        case 'servingStatus':
          c = a.learningStatus ? a.learningStatus : a.servingStatus?.servingStatus
          d = b.learningStatus ? b.learningStatus : b.servingStatus?.servingStatus
          return this.defaultSort(c, d)
        default:
          if (this.sortExtension) {
            const extensionCompare = this.sortExtension(a, b, key)
            if (extensionCompare !== null) {
              return extensionCompare
            }
          }
          if (formatter) {
            c = formatter(a[key], key, a)
            d = formatter(b[key], key, b)
            return this.defaultSort(c, d)
          }
          return this.defaultSort(a, b, key, sortDesc, formatter, compareOptions, compareLocale)
      }
    },

    defaultSort (aRow, bRow, key, sortDesc, formatter, compareOptions, compareLocale) {
      // debugger
      let firstSortItem, secondSortItem
      if (key) { // adding sort check in here for backwards compatible default sort which is setup by fluent sorter above -SNG
        firstSortItem = aRow[key]
        secondSortItem = bRow[key]
      } else {
        firstSortItem = aRow
        secondSortItem = bRow
      }
      if (
        (typeof firstSortItem === 'number' && typeof secondSortItem === 'number') ||
        (firstSortItem instanceof Date && secondSortItem instanceof Date)
      ) {
        // If both compared fields are native numbers or both are native dates
        return firstSortItem < secondSortItem ? -1 : firstSortItem > secondSortItem ? 1 : 0
      } else {
        // Otherwise stringify the field data and use String.prototype.localeCompare
        return this.toString(firstSortItem).localeCompare(this.toString(secondSortItem), compareLocale, compareOptions)
      }
    },
    toString (value) {
      if (value === null || typeof value === 'undefined') {
        return ''
      } else if (value instanceof Object) {
        return Object.keys(value).chainedSort().map(key => toString(value[key])).join(' ')
      } else {
        return String(value)
      }
    },
    toggleAllSelected () {
      if (this.selectedRowIds.length < this.numSelectableItems) {
        this.selectAll()
      } else if (this.selectedRowIds.length === this.numSelectableItems) {
        this.deselectAll()
      }
    },
    selectAll () {
      this.selectedRowIds = [...this.selectableKeys]
    },
    deselectAll () {
      this.selectedRowIds = []
    },
    selectSome (rowIds = []) {
      if (this.isSingleSelectMode) {
        rowIds = rowIds.length > 1 ? rowIds.splice(1, rowIds.length - 1) : rowIds
        if (this.selectedRowIds.length > 0) {
          this.deselectAll()
        }
      }
      this.selectedRowIds = [...this.selectedRowIds, ...rowIds.filter(id => !this.selectedRowIds.includes(id))]
    },
    deselectSome (rowIds = []) {
      this.selectedRowIds = this.selectedRowIds.filter(id => !rowIds.includes(id))
    },
    toggleSomeSelected (rowIds = []) {
      const select = []
      const deselect = []
      rowIds.forEach(id => {
        if (this.selectedRowIds.includes(id)) {
          deselect.push(id)
        } else {
          select.push(id)
        }
      })
      if (select.length > 0) {
        this.selectSome(select)
      }
      if (deselect.length > 0) {
        this.deselectSome(deselect)
      }
    },
    catchFilteredItems (filteredItems) {
      if (this.filteredItems?.length !== filteredItems?.length) {
        this.currentPageFirstIndex = 0
      }
      this.filteredItems = filteredItems
    },
    convertFromCamelCase (val) {
      if (val === 'cpc') {
        return 'CPC'
      } else if (val === 'ctr') {
        return 'CTR'
      }
      if (val && typeof val === 'string') {
        const result = val.replace(/([A-Z])/g, ' $1')
        const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
        return finalResult
      }
      return ''
    },
    convertObjectToString (obj) {
      let str = ''
      for (const itm in obj) {
        if (!itm.includes('Id')) {
          str += `${itm}: ${obj[itm]};`
        }
      }
      return str
    },
    // calculates the hover over / title
    calculateTitle (campaignPlans) {
      if (!campaignPlans) return ''
      let tle = campaignPlans.length > 0 ? 'Active Campaigns\n' : ''
      for (let i = 0; i < campaignPlans.length; i++) {
        tle += '* ' + campaignPlans[i]?.name + '\n'
        if (i > 10) {
          tle += '...'
          break
        }
      }
      return tle
    },
    buildAssignedUserMailtoLink (row) {
      if (!row.item.assignedUserName || !row.item.assignedUserName.includes('@')) {
        return ''
      }
      let mailtoString = `mailto:${row.item.assignedUserName}`
      const queryParts = []

      if (row.item.name) {
        queryParts.push(`subject=Regarding ${row.item.name}`)
      } else {
        queryParts.push('subject=Regarding Fluency Account')
      }

      if (row?.item?.accountPlanId) {
        const bodyString = `\n\nhttps://backpack.fluency.inc/manage/account/?id=${row.item.accountPlanId}`
        queryParts.push(`body=${bodyString}`)
      }

      if (queryParts.length > 0) {
        mailtoString += `?${queryParts.join('&')}`
      }
      return encodeURI(mailtoString)
    },
    keydownHandler (event) {
      if (event.which === 16) { // shift
        this.isShiftEnabled = true
      }
    },
    keyupHandler (event) {
      if (event.which === 16) { // shift
        this.isShiftEnabled = false
      }
    },
    preventSelectionWhenShifting (event) {
      if (this.isShiftEnabled) {
        event.preventDefault()
      }
    },
    doSelectLogic (row, val) {
      const id = row.item[this.primaryKey]
      if (!this.isShiftEnabled || this.isSingleSelectMode) {
        if (val) {
          // deselect one
          this.selectedRowIds.push(id)
        } else {
          const index = this.selectedRowIds.indexOf(id)
          if (index > -1) {
            this.selectedRowIds.splice(index, 1)
          }
        }
      } else {
        if (this.selectedRowIds.length === 0) {
          return
        }
        let fromIndex = this.previouslySelectedRowIndex
        let toIndex = row.index
        if (fromIndex > toIndex) {
          const temp = fromIndex
          fromIndex = toIndex
          toIndex = temp
        }
        const newSelectionIds = this.pagedItems
          .map(item => item[this.getTableRowKey()])
          .slice(fromIndex, toIndex + 1)

        if (this.selectedRowIds.includes(id)) {
          // deselect multiple
          this.deselectSome(newSelectionIds)
        } else {
          // select multiple
          this.selectSome(newSelectionIds)
        }
      }
      this.previouslySelectedRowIndex = row.index
    },
    loadHiddenRowItems (item) {
      this.hoveredRowLazyDebounce(this, item[this.getTableRowKey()])
    },
    hoveredRowLazyDebounce: _debounce((vthis, key) => {
      // map of all hovered
      if (!vthis.hoveredRowMap[key]) {
        vthis.hoveredRowMap[key] = true
      }
    }, 25),
    async loadTableUserPrefs () {
      const fieldKeys = this.fields.map(field => field.slot || field.key || field)
      // loadTableUserPrefs only needs to be run when the defaultFields change, so do some debouncing by comparing the list of previous default fields keys with the current ones
      // this resets the default column widths and merges it with custom column widths
      // then enhances the table columns
      if (this.defaultFieldKeys.length !== fieldKeys.length || Array.zip(this.defaultFieldKeys, fieldKeys, (orig, newVal) => orig !== newVal).some(areNotEqual => areNotEqual)) {
        // diff detected
        this.defaultFieldKeys = fieldKeys

        this.loadDefaultColumnWidthMap()

        if (this.columnSaveKey) {
          await Promise.all([
            this.$store.dispatch('table/getColumns', { columnKey: this.columnSaveKey, type: 'order' }),
            this.loadColumnWidths()
          ])
        }
        this.enhanceTableColumns()
        this.sortAndFilter(this.columnFilters)
      }
    },
    loadDefaultColumnWidthMap () {
      const defaultWidth = 82
      const defaultWidthMap = this.fields
        .map(field => {
          const key = field.slot || field.key || field
          const width = field.defaultWidth ?? supportedFields[key]?.defaultWidth ?? defaultWidth
          return { key, width }
        })
        .toMap(cw => cw.key, cw => cw.width)
      this.defaultColumnWidthMap = defaultWidthMap
      this.computedColumnWidths = { ...defaultWidthMap }
      return defaultWidthMap
    },
    async loadColumnWidths () {
      const columnWidths = await this.$store.dispatch('table/getColumns', {
        columnKey: this.columnSaveKey,
        type: 'width'
      })
      if (Array.isArray(columnWidths)) {
        const customWidthMap = columnWidths.toMap(cw => cw.key, cw => cw.width)
        this.computedColumnWidths = {
          ...this.defaultColumnWidthMap,
          ...customWidthMap
        }
      }
    },
    async saveColumnWidths () {
      if (this.columnSaveKey) {
        const payload = Object.entries(this.computedColumnWidths)
          .map(([key, width]) => ({ key, width }))
          .filter(colWidth => this.defaultColumnWidthMap[colWidth.key] !== colWidth.width)
        await this.$store.dispatch('table/setColumns', {
          columnKey: this.columnSaveKey,
          columnObject: payload,
          type: 'width'
        })
      }
    },
    enhanceTableColumns () {
      const enhancedFields = []
      const visibleOrderedFields = this.$store.getters['table/visibleFields'](this.columnSaveKey, this.fields)
      const fieldsToCheck = visibleOrderedFields.length > 0 ? visibleOrderedFields : this.fields
      for (let i = 0; i < fieldsToCheck.length; i++) {
        let field = ''
        const fieldDefinition = fieldsToCheck[i]

        if (typeof fieldDefinition === 'object') {
          if (fieldDefinition.slot && supportedFields[fieldDefinition.slot] && (fieldDefinition.useDefaultTemplate || typeof fieldDefinition.useDefaultTemplate === 'undefined')) {
            field = {
              option: fieldDefinition.slotOption || fieldDefinition.option,
              resizable: true,
              ...supportedFields[fieldDefinition.slot],
              ...fieldDefinition,
              useDefaultTemplate: true
            }
          } else {
            field = {
              sortable: !fieldDefinition.filter,
              class: 'text-left',
              resizable: true,
              ...(fieldDefinition.inherit ? supportedFields[fieldDefinition.key] || {} : {}),
              ...fieldDefinition,
              slot: fieldDefinition.key
            }
          }
        } else {
          field = supportedFields[fieldDefinition]
            ? { resizable: true, ...supportedFields[fieldDefinition], useDefaultTemplate: true, slot: fieldDefinition }
            : { key: fieldDefinition, resizable: true, sortable: true, class: 'text-left', slot: fieldDefinition }
        }

        if (field.filter) { // force false if filter is defined
          field.sortable = false
        }

        field.thAttr = { ...field.thAttr, style: (field.thAttr?.style ?? '') + `width: ${this.computedColumnWidths[field.slot]}px;` }
        field.tdAttr = { ...field.tdAttr, style: (field.tdAttr?.style ?? '') + `width: ${this.computedColumnWidths[field.slot]}px;` }

        if (this.groupedColumnsList.indexOf(field.slot) >= 0) {
          field.tdClass = (field.tdClass) ? field.tdClass + ' grouped' : 'grouped'
          field.thClass = (field.thClass) ? field.thClass + ' grouped' : 'grouped'
        }

        field.class = (field.class) ? field.class + ' ' + field.key : field.key

        if (field.filter) {
          field.thClass = [...(field.thClass ? [field.thClass] : []), this.sortIconLeft ? 'fluency-table-enhanced-column-th-left' : 'fluency-table-enhanced-column-th'].join(' ')
        }
        // automatically set filter on any numerical field
        if (this.numericColumnFilters && ['number', 'percentage', 'currency', 'currency-precise', 'fixed-2'].includes(field?.summable?.format)) {
          field.filter = true
          field.sortable = field.sortable || false
          field.filterOptions = ['numeric']
          // field.thClass = (field.thClass) ? field.thClass + ' fluency-table-enhanced-column-th' : 'fluency-table-enhanced-column-th'
          field.thClass = [...(field.thClass ? [field.thClass] : []), this.sortIconLeft ? 'fluency-table-enhanced-column-th-left' : 'fluency-table-enhanced-column-th'].join(' ')
        }
        enhancedFields.push(field)
      }

      // re-order any sticky columns to the front
      const stickyFields = enhancedFields.filter(field => field.sticky)
      stickyFields.forEach((stickyField, idx) => {
        enhancedFields.move(enhancedFields.indexOf(stickyField), idx)
        stickyField.tdClass = 'sticky ' + (stickyField.tdClass || '')
        stickyField.thClass = 'th-sticky ' + (stickyField.thClass || '')

        const prevStickyField = idx > 0 ? stickyFields[idx - 1] : null
        const left = idx === 0 ? 0 : (prevStickyField.left || 0) + this.computedColumnWidths[prevStickyField.slot] // calculate left value by summing previous sticky column widths
        if (left >= 0) {
          stickyField.left = left
          stickyField.thAttr = { ...stickyField.thAttr, style: (stickyField.thAttr?.style ?? '') + `left: ${left}${left > 0 ? 'px' : ''};` }
          stickyField.tdAttr = { ...stickyField.tdAttr, style: (stickyField.tdAttr?.style ?? '') + `left: ${left}${left > 0 ? 'px' : ''};` }
        }

        if (idx === stickyFields.length - 1) {
          stickyField.tdClass += ' last-sticky'
          stickyField.thClass += ' last-sticky'
        }
      })

      // apply index to resorted fields
      enhancedFields.forEach((field, i) => {
        field.index = i
      })
      this.enhancedColumnFieldDefinitions = enhancedFields
      this.calcTotals()
    },
    isFieldVisible (fieldObj) {
      const fieldKey = fieldObj.slot || fieldObj.key || fieldObj
      return this.enhancedColumnFieldDefinitions.findIndex(enhancedField => enhancedField.slot === fieldKey) !== -1
    },
    handleColumnResized (newColumn) {
      const { column, columnWidth } = newColumn
      this.computedColumnWidths[column] = columnWidth
      this.saveColumnWidths()

      // set new value in fields prop
      const index = this.enhancedColumnFieldDefinitions.findIndex(f => f.slot === column)
      const field = this.enhancedColumnFieldDefinitions[index]
      const headingStyle = field.thAttr.style.replace(/width:\s*\d+px;/, '') + `width: ${columnWidth}px;`
      const dataStyle = field.tdAttr.style.replace(/width:\s*\d+px;/, '') + `width: ${columnWidth}px;`
      this.enhancedColumnFieldDefinitions[index] = { ...field, thAttr: { ...field.thAttr, style: headingStyle }, tdAttr: { ...field.tdAttr, style: dataStyle } }
    },
    getAdvertisingChannelLabel (id) {
      return this.$store.getters.advertisingChannels?.find(ch => ch.advertisingChannelId === id)?.channelLabel.replace('DDC RTB ', '').replace('Ingested', 'Legacy')
    },
    removeBar () {
      this.deselectAll()
    },
    storeSubscribe (mutation) {
      if (mutation.type === 'table/clearAllSelectedRows') {
        this.selectedRowIds = []
      }
    },
    calcSubFields () {
      const fieldOverrides = {
        approvedAccountBudget: {
          slot: 'approvedAccountBudget',
          option: 'static',
          tdClass: 'hide-cell-contents'
        },
        conservativeBudgetIncrease: {
          slot: 'conservativeBudgetIncrease',
          option: 'hide',
          tdClass: 'hide-cell-contents'
        },
        keywordText: {
          slot: 'keywordText',
          option: 'static'
        },
        budget: {
          slot: 'budget',
          option: 'no-highlight'
        },
        name: {
          slot: 'name',
          option: 'static'
        },
        bidAmount: {
          slot: 'bidAmount',
          option: 'static'
        },
        budgetName: {
          slot: 'budgetName',
          option: 'static'
        },
        budgetAmount: {
          slot: 'budgetAmount',
          option: 'static'
        },
        optimizedDailyAmount: {
          slot: 'optimizedDailyAmount',
          option: 'dynamic'
        },
        nextBudgetAmount: {
          slot: 'nextBudgetAmount',
          option: 'static'
        },
        budgetType: {
          slot: 'budgetType',
          option: 'static'
        },
        deliveryMethod: {
          slot: 'deliveryMethod',
          option: 'static'
        },
        budgetBiasStrategy: {
          slot: 'budgetBiasStrategy',
          option: 'static'
        },
        allowReallocation: {
          slot: 'allowReallocation',
          option: 'static',
          tdClass: 'pr-3'
        },
        proratePartialMonth: {
          slot: 'proratePartialMonth',
          option: 'static',
          tdClass: 'pr-3'
        },
        useBroadMatchOnUnderspend: {
          slot: 'useBroadMatchOnUnderspend',
          option: 'static',
          tdClass: 'pr-3'
        },
        openAdSchedulesOnUnderspend: {
          slot: 'openAdSchedulesOnUnderspend',
          option: 'static',
          tdClass: 'pr-3'
        },
        geoTargetExpansionAbsoluteMaxInMiles: {
          slot: 'geoTargetExpansionAbsoluteMaxInMiles',
          option: 'static'
        },
        bidAdjustmentPercentageOnUnderspend: {
          slot: 'bidAdjustmentPercentageOnUnderspend',
          option: 'static'
        },
        stat: {
          slot: 'stat',
          option: 'static'
        },
        servingStatus: {
          tdClass: 'hide-cell-contents'
        },
        dashboardLink: {
          tdClass: 'hide-cell-contents'
        },
        assignedUserName: {
          tdClass: 'hide-cell-contents',
          class: 'text-left assignedUserName'
        },
        budgetShared: {
          tdClass: 'hide-cell-contents'
        },
        autoRemoveBlockingKeywordOnUnderspend: {
          option: 'static'
        },
        nextApprovedAccountBudget: {
          option: 'static'
        },
        creativePlan: {
          option: 'partner'
        }
      }
      const subFields = this.enhancedColumnFieldDefinitions
        .map(field => ({
          ...field,
          ...(fieldOverrides[field.slot || field.key] || {})
        }))

      this.subFields = subFields
      this.subFieldKeyCounter++
    }
  },
  async mounted () {
    document.addEventListener('keydown', this.keydownHandler)
    document.addEventListener('keyup', this.keyupHandler)
    document.addEventListener('selectstart', this.preventSelectionWhenShifting)
    if (this.fields.find(field => field.slot === 'contentTemplateName')) {
      const bps = await this.$store.dispatch('fetchMinBlueprints')
      this.blueprints = bps
    }
    this.appliedFiltersHash = ''

    if (this.selectedRowObjs.length > 0) {
      this.selectedRowIds = this.selectedRowObjs.map(row => row[this.getTableRowKey()])
    }

    // Listen for external clear rows commit to clear local selected rows
    if (!this.maintainSelection) {
      this.$store.subscribe(this.storeSubscribe)
    }
  },
  beforeUnmount () {
    document.removeEventListener('keydown', this.keydownHandler)
    document.removeEventListener('keyup', this.keyupHandler)
    document.removeEventListener('selectstart', this.preventSelectionWhenShifting)
    this.appliedFiltersHash = ''
    const index = this.$store._subscribers.indexOf(this.storeSubscribe)
    if (index >= 0) {
      this.$store._subscribers.splice(index, 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-bar-min-height {
  min-height: 46px;
}
.filter-and-bulk-bar {
  background: var(--p-primary-100);
  z-index: 9;
}
.fluent-table-bottom-scrollbar-buffer {
  height: 12px;
}
.underspend-legend:before {
  content: ' ';
  display: inline-flex;
  margin-right: .1rem;
  width: 1rem;
  height: 1rem;
  border: 1px solid var(--p-surface-100);
  background-color: var(--p-surface-50);
}

</style>

<style lang="scss">
  .drawer {
    .filter-and-bulk-bar {
      z-index: 1021;
    }
  }
  .hidden {
    visibility: hidden;
  }
  .guardrails-active {
    .guardrail-concern,
    .guardrail-warning,
    .guardrail-alarm {
      background-size: 2em 2em;
      background-repeat: no-repeat;
      background-position: -5px 2px;
    }

    .guardrail-concern {
      background-image: radial-gradient($info 20%, transparent 20%);
      color: $info;
    }

    .guardrail-warning {
      background-image: radial-gradient($warning 20%, transparent 20%);
      color: $warning;
    }

    .guardrail-alarm {
      background-image: radial-gradient($danger 20%, transparent 20%);
      color: $danger;
    }
  }

  .guardrails-active {
    .guardrail-concern,
    .guardrail-warning,
    .guardrail-alarm {
      background-position: 0 2px;
    }
  }

  thead th:first-child,
  tfoot th:first-child {
    z-index: 5;
  }

  .sticky {
    position: sticky;
    background-color: inherit;
    z-index: 3;
  }

  /* table-scroll class is applied when fixedHeader prop is true */
  .table-scroll {
    position: relative;
    width: 100%;
    z-index: 1;
    overflow: auto;

    &.overflow-visible {
      overflow: visible;
    }

    .column-resize-handle {
      height: 100%;
      width: 20px;
      cursor: col-resize;
      position: absolute;
      right: 0;
      top: 0;
      thead:hover & {
        border-right: 1px solid $fluency-lightergray
      }
    }

    table {
      width: 100%;
      margin: auto;
      border-collapse: collapse;
      border-spacing: 0;
      table-layout: fixed;
    }

    //.table thead tr th {
    //  border: none;
    //  box-shadow: inset 0px 1px #99b898, inset 0px -1px #99b898;
    //}

    tr {
      &:last-child {
        padding-bottom: 5rem;
      }
      &:focus {
        outline: 0;
      }
    }

    thead,
    tfoot,
    td, th {
      padding: 5px 10px;
    }
    th[role="rowheader"]:not(.customTableHeading) {
      padding: 0;
    }

    th {
      position: sticky;
      background-color: white;
      z-index: 4;
      &.th-sticky {
        z-index: 5
      }
    }

    thead th {
      top: 0
    }
    tfoot th {
      bottom: 0
    }

    .selectRow {
      padding-right: 0;

      .custom-control-inline {
        margin-right: 0;
      }

      .custom-control-inline label {
        margin-right: 0;
      }
    }

    th {
      white-space: normal;
    }
    td {
      &.truncate,
      .truncate {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    td.last-sticky::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      border-right: 1px solid $fluency-green;
    }
  }

  /* table expander styling
   * in manage table, details open is a "sub" fluent table
   * and this selector hides the checkbox
   */
  .details-row .hide-select-row .selectRow > div {
    visibility: hidden;
  }

  .keywordText .hover-icon, .keywordText + .hover-icon {
    visibility: hidden;
  }

  .keywordText:hover .hover-icon, .keywordText:hover + .hover-icon {
    visibility: visible;
  }

  /* === BEGIN CUSTOM HEADER == ====================================================================================== */
  th,
  td {
    &.grouped {
      border-left: 2px solid #cdcdcd;
    }
  }

  th.grouped + th.grouped,
  td.grouped + td.grouped {
    border-left-width: 0;
  }

  tr > th.grouped + :not(.grouped),
  tr > td.grouped + :not(.grouped) {
    border-left: 2px solid #cdcdcd;
  }

  thead th.customTableHeading {
    border-left: 2px solid #cdcdcd;
    border-top: 2px solid #cdcdcd !important;
    background: #fff;
    padding: 2px 10px;
  }

  th.customTableHeading + th:not(.customTableHeading) {
    border-left: 2px solid #cdcdcd;
  }
  /* === END CUSTOM HEADER ========================================================================================== */

  td.hide-cell-contents * {
    visibility: hidden;
  }

  .table td {
    vertical-align: middle;
  }

  .inline-loading {
    transition: 0.2s ease;
    opacity: 0.25;
    pointer-events: none;
  }
  .inline-editable-value {
    min-height: 24px;
  }
</style>
