<template>
  <div v-if="selectedRows && selectedRows.length"
       class="btn-toolbar bulk-edit-bar align-items-center table-bulk-edit-bar"
       role="toolbar"
       aria-label="Toolbar with button groups">
    <div class="border-right px-3 align-self-center">
      ({{ selectedRows.length }})
      <template v-if="!briefSelectionText"> {{ $filters.convertFromCamelCase(label) }} </template>
      Selected
      <template v-if="!briefSelectionText"> for Bulk Edit</template>
    </div>
    <fluency-loader v-if="loading" dots invert />
    <t-button-group v-else class="text-white">
      <template  v-for="(button, count) in buttons" :key="count">
        <component v-bind="button.props || {}"
                   v-if="button.type === 'component'"
                   :is="button.component"/>
        <template v-else-if="button.type === 'dropdown'">
          <t-dropdown v-if="(button.dropdownItems && button.dropdownItems.length > 0)"
                      :key="`button-${count}`"
                      :text="button.label"
                      variant="text">
            <template v-for="(dropdownItem, count) in button.dropdownItems" :key="count">
              <t-dropdown-header v-if="dropdownItem.type === 'dropdown-header'">
                {{dropdownItem.label}}
              </t-dropdown-header>
              <t-dropdown-divider v-else-if="dropdownItem.type === 'dropdown-divider'" />
              <t-dropdown-item v-else-if="dropdownItem.type === 'dropdown-alert'"
                               disabled>
                <t-alert variant="info" show class="mb-0 white-space-pre-line">{{dropdownItem.label}}</t-alert>
              </t-dropdown-item>
              <t-dropdown-item-button v-else
                                      @click="btnClicked(dropdownItem, button)">
                {{ dropdownItem.label }}
              </t-dropdown-item-button>
            </template>
          </t-dropdown>
        </template>
        <t-button v-else-if="button.type === 'expandCollapse'"
                  :key="`button-${count}`"
                  variant="text"
                  :id="button.buttonId || null"
                  @click.stop="toggleExpandCollapse">
          {{ button.label || 'Expand/Collapse'}}
        </t-button>
        <t-button v-else-if="button.shortkey"
                  :key="`button-${count}-shortkey`"
                  variant="text"
                  :id="button.buttonId || null"
                  v-shortkey="button.shortkey"
                  @shortkey="btnClicked(button, null, button.shortKey)"
                  @click.stop="btnClicked(button)">{{ button.label }}
        </t-button>
        <t-button v-else
                  :key="`button-${count}-plainButton`"
                  variant="text"
                  :disabled="button.disabled"
                  :id="button.buttonId || null"
                  :class="[(button.classes) ? button.classes : '']"
                  @click.stop="btnClicked(button)"
                  data-qa="tableBulkEditBar-button">
          <fluency-icon v-if="button.icon" :type="button.icon" />
          {{ button.label }}
        </t-button>
      </template>
    </t-button-group>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'
export default {
  name: 'tableBulkEditBar',
  components: { FluencyLoader },
  props: {
    buttons: Array,
    label: String,
    selectedRowKey: String,
    briefSelectionText: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      expandCollapseEvent: 'toggleExpandCollapse'
    }
  },
  computed: {
    selectedRows () {
      return this.$store.getters['table/selectedRowObjs'](this.selectedRowKey) || []
    }
  },
  methods: {
    async btnClicked (btn, parentItem, shortKey) {
      this.trackAction(btn, parentItem, shortKey) // no need to await this
      this.loading = true
      await btn.action(btn)
      this.loading = false
    },
    toggleExpandCollapse () {
      this.trackAction(this.expandCollapseEvent)
      this.$emit(this.expandCollapseEvent)
    },
    async trackAction (btn, parentItem, shortKey) {
      let btnAction = ''
      if (btn === this.expandCollapseEvent) {
        btnAction = this.expandCollapseEvent
      } else if (parentItem?.label) {
        btnAction = parentItem.label
      } else {
        btnAction = btn?.label || btn?.type || ''
      }
      const payload = {
        selectedRowType: this.selectedRowKey,
        path: this.$route.path,
        btn: btnAction,
        shortKeyed: shortKey ? 1 : 0,
        nSelected: this.selectedRows.length,
        label: btnAction
      }
      try {
        this.$track.event('bulk-toolbar-action', payload, 'manage')
      } catch (err) {
        this.$bugsnag(new Error('Submitting user event for bulk toolbar action', { metaData: payload }))
      }
    }
  }
}
</script>

<style scoped lang="scss">
.bulk-edit-bar {
  z-index: 5;
  width: 100%;
  height: 46px;
}
</style>
<style lang="scss">
.table-bulk-edit-bar {
  .btn-group {
    .btn, .p-button {
      &:not(.reset) {
        color: white;
        text-transform: capitalize;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .dropdown-menu {
    width: 310px;
    max-height: 30em;
    overflow: auto;
  }

  .dropdown-item {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
