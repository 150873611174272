<template>
  <div style="margin-top:-0.5em;margin-bottom:-0.15em;margin-left:-1.1em;">
    <b-input-group>
      <b-form-input v-model="editName"/>
      <b-input-group-append>
        <t-button size="sm" variant="ghost-white-secondary" @click="$emit('cancel')">
          <fluency-icon type="x"/>
        </t-button>
        <t-button size="sm" variant="ghost-white-success" @click="save()">
          <fluency-icon type="save" class="text-success"/>
        </t-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
export default {
  name: 'editPartnerName',
  props: ['value', 'planType'],
  data () {
    return {
      editName: '',
      originalEdit: false
    }
  },
  computed: {
    objectName () {
      return this.value.name
    },
    objectId () {
      return this.value[`${this.planType}Id`]
    },
    activeItemAccountPlanId () {
      return this.$store.getters.activeItem?.accountPlanId
    }
  },
  watch: {
    objectName: {
      handler  (newValue, oldValue) {
        if (newValue) {
          this.editName = this.objectName
        }
        if (!this.originalEdit) {
          this.originalEdit = this.objectName
        }
      },
      immediate: true
    }
  },
  methods: {
    async save () {
      const response = await this.$res.set.accountNamingOverride({
        name: this.editName,
        objectType: this.planType,
        objectId: this.objectId,
        accountPlanId: this.value.accountPlanId || this.activeItemAccountPlanId
      })
      if (response) {
        this.$emit('save', this.editName)
        this.$toast('Name Updated', 'success')
      }
    }
  }
}
</script>

<style scoped lang="scss">
/* ===== GREEN ===== */
.btn-ghost-white-success {

}

@mixin ghostButton($color, $hoverColor, $textColor: $white) {
  color: $color;
  background-color: white;
  border:1px solid #ced4da;

  &:not([disabled]):hover,
  &:not([disabled]).active {
    border-color: $hoverColor;
    background-color: $hoverColor;
    color: white;

    .fluency-icon {
      color: white;
    }

    box-shadow: 0 0 0 0.2rem rgba(130,138,145,.5);

  }
  &:focus {
    box-shadow: none;
  }
}

.btn-ghost-white-secondary {
  @include ghostButton($secondary, $secondary, $secondary);
}

.btn-ghost-white-success {
  @include ghostButton($fluency-green, $fluency-green, $fluency-green);

  .fluency-svg-icon {
    path,
    polyline,
    polygon {
      fill: $fluency-green !important;
      stroke: $fluency-green !important;
    }
  }
}
</style>
