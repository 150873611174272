<template>
  <div v-p-tooltip="{ value: labelList, disabled: !useTooltip }">
    <p-chip v-for="label in labels"
            :key="label.labelText"
            :style="`background-color: ${label.labelColor};`"
            class="label-badge text-white">
      {{label.labelText}}
    </p-chip>
  </div>
</template>

<script>
export default {
  name: 'labelsDisplay',
  props: {
    labels: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    labelList () {
      return this.labels.map(label => label.labelText).join(', ') ?? ''
    },
    useTooltip () {
      return this.labelList.length > 33
    }
  }
}
</script>

<style scoped>
</style>
